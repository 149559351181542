import { ICategory } from "api/types/_category";
import { IMedia, IType } from "api/types/_media";
import { IPreviewNews } from "api/types/_news";
import { Option } from "api/types/_public";
import { ITemplate } from "api/types/_template";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import CategoryType from "components/Common/CategoryType";
import DropdownCategoryKeyword from "components/Common/DropdownCategoryKeyword";
import DropdownOption from "components/Common/DropdownOption";
import DropdownSectionCodeMediaType from "components/Common/DropdownSectionCodeMedia";
import Hashtag from "components/Common/Hashtag";
import ModalConfirm from "components/Common/ModalConfirm";
import ScheduleType from "components/Common/ScheduleType";
import SearchFilterKeyword from "components/Common/SearchFilterKeyword";
import SearchFilterMedia from "components/Common/SearchFilterMedia";
import SearchTemplate from "components/Common/SearchTemplate";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import {
    ACTION_TYPE_CAMPAIGN,
    SCHEDULE_MONTHLY_OPTIONS,
    SCHEDULE_OPTIONS,
    SCHEDULE_WEEKLY_OPTIONS,
    TYPE_SHOW_MORE_HASHTAG
} from "helpers/constans";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Card,
    CardBody,
    Col,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import {
    getDetailCampaign,
    postCampaignMultiMedia,
    previewNews,
    putCampaignMultiMedia
} from "store/thunks";
import * as Yup from "yup";

export interface Tag {
    id: string;
    text: string;
}

export interface Props {
    typeCampaignForm?: string;
    categoryTypeDefaultValue?: string;
    templateCategoryDefaultValue?: string | string[],
    isModal?: boolean;
    id?: string;
    isCopy?: boolean,
    listType: IType[],
    listMedia: IMedia[],
    listTemplate: ITemplate[],
    listCategoriesType: Option[],
    listClassifications?: Option[],
    listCategoriesNaver?: ICategory[],
    listCategoriesStock?: ICategory[],
    listCategoriesCar?: ICategory[],
    listCategoriesTrendIndex: ICategory[],
    listCategoriesCoin?: ICategory[],
    listCategoriesRankify?: ICategory[],
    triggerRefresh?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const CampaignFormMulti = ({
    typeCampaignForm = '',
    categoryTypeDefaultValue = '',
    templateCategoryDefaultValue = '',
    isModal = false,
    id = '',
    isCopy = false,
    listType = [],
    listMedia = [],
    listTemplate = [],
    listCategoriesType = [],
    listClassifications = [],
    listCategoriesNaver = [],
    listCategoriesStock = [],
    listCategoriesCar = [],
    listCategoriesTrendIndex = [],
    listCategoriesRankify = [],
    listCategoriesCoin = [],
    triggerRefresh,
}: Props) => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();
    // const { id = "" } = useParams();

    const SCHEDULE_OPTIONS_LANG = SCHEDULE_OPTIONS?.map((item: any) => ({
        value: item?.value,
        label: t(item?.label),
    }));

    const OPTIONS_MUST_HAVE_DATE = ["weekly", "monthly"];

    const OPTIONS_MUST_HAVE_PUBLISHING = ["daily", "weekly", "monthly"];

    const OPTIONS_MUST_HAVE_CATEGORY_AND_KEYWORD = ["trend_index", "stock", "car", "coin", "dc_trend", "press_ai"];

    const LIST_TYPE_CATEGORY_NOT_SCHEDULE = ['SPORT', 'LOTTO'];

    const CATEGORY_TYPE_CREATE_BY_FORM_NORMAL = LIST_TYPE_CATEGORY_NOT_SCHEDULE?.includes(typeCampaignForm) ? [String(typeCampaignForm).toLowerCase()] : ['trend_index', 'exchange_rate', 'car', 'movie', 'stock', 'coin', 'dc_trend', 'press_ai'];

    const MONTHLY_OPTIONS =
        SCHEDULE_MONTHLY_OPTIONS?.map((item: Option) => ({
            ...item,
            type: "monthly",
        })) || [];
    const WEEKLY_OPTIONS =
        SCHEDULE_WEEKLY_OPTIONS?.map((item: Option) => ({
            ...item,
            type: "weekly",
        })) || [];
    const DATE_OPTIONS_LANG = MONTHLY_OPTIONS.concat(WEEKLY_OPTIONS)?.map(
        (item: any) => ({ ...item, label: t(item?.label) })
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);

    const [isOpenPreview, setIsOpenPreview] = useState<boolean>(false);

    const [isPreviewLoading, setIsPreviewLoading] = useState<number | null>(null);

    const [previewTemplate, setPreviewTemplate] = useState<IPreviewNews | null>(
        null
    );

    const listCategoriesTypeLang = listCategoriesType?.reduce((arr: any, item: any) => {
        if (CATEGORY_TYPE_CREATE_BY_FORM_NORMAL?.includes(item?.value)) {
            return ([...arr, ({
                ...item,
                label: t(`Category_Type_[${item?.value}]_For_Campaign`),
            })]);
        }
        return arr;
    }, []) || [];

    // Begin::Classification options
    const listClassificationsLang = listClassifications?.map((item: any) => ({
        ...item,
        label: t(`${item?.value}`),
    }))
    // End::Classification options

    const [isOpenConfirmCreate, setIsConfirmCreate] = useState<boolean>(false);


    const handleShowConfirmCreateCampaign = () => {
        if (formik?.values?.action_type === ACTION_TYPE_CAMPAIGN.SAVE) {
            handleSubmit(formik.values);
        } else {
            setIsConfirmCreate((_prev) => true);
        }
    };

    const handleCreateCampaign = () => {
        setIsConfirmCreate((_prev) => false);
        handleSubmit(formik.values);
    }

    const onCloseConfirmCreateClick = () => {
        setIsConfirmCreate((_prev) => false);
    }

    const handleSubmit = async (values: any) => {

        try {
            setIsLoading((_prev) => true);
            const data = {
                schedule: values?.schedule?.value || "now",
                publishing_schedule: values?.publishing_schedule?.value || "now",
                category_id: values?.category?.value ? String(values?.category?.value) : null,
                classification: values?.classification?.value || null,
                type: values?.category_type?.value,
                keywords: [], // values?.keywords?.map((item: Option) => item?.value) ||
                tag_related_news: (values?.tags || [])?.map((item: Tag) => item?.id).join(",") || "",
                action_type: values?.action_type || ACTION_TYPE_CAMPAIGN.POST,
                multiple: true,
                partners: (values?.sections || [])?.reduce((arr: any, item: any) => {
                    if (item?.id) {
                        return [...arr, {
                            partner_id: item?.media?.value ?? "",
                            partner_code_id: item?.section_code?.value || '',
                            title_template_id: item?.title_template_id?.value ?? "",
                            body_template_id: item?.body_template_id?.value ?? "",
                            is_random_chart: item?.is_random_chart || false,
                            date: OPTIONS_MUST_HAVE_DATE.includes(values?.schedule?.value) ? item?.date?.value || "" : "",
                            time: values?.schedule?.value === "now" ? "" : item?.time || "",
                            publishing_date: OPTIONS_MUST_HAVE_DATE.includes(values?.publishing_schedule?.value) ? item?.publishing_date?.value || "" : "",
                            publishing_time: values?.publishing_schedule?.value === "now" ? "" : item?.publishing_time || "",
                        }];
                    }
                    return arr;
                }, []) || [],
            };
            const response: any = (id && !isCopy) ? await putCampaignMultiMedia(id, data) : await postCampaignMultiMedia(data);
            if (response?.data) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                if (id) {
                    triggerRefresh && triggerRefresh();
                } else {
                    navigate("/campaign/trend-ranking-news/list");
                }
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const sectionSchema = Yup.lazy((item) => {
        return Yup.object().shape({
            media: Yup.object().test('validate media', `${t('Media is required')}`, function (value) {
                return !!value;
            }).required(`${t('Media is required')}`),
            section_code: Yup.object().shape({
                label: Yup.string(),
                value: Yup.string(),
            }).nullable(),
            date: Yup.object().shape({
                label: Yup.string(),
                value: Yup.string(),
            }).when('schedule', {
                is: (schedule: any) => (OPTIONS_MUST_HAVE_DATE?.includes((formik.values?.schedule as any)?.value)),
                then: (schema) => Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string(),
                }).required(`${t('Day is required')}`),
            }),
            time: Yup.string().when(['schedule', 'category_type'], {
                is: (schedule: any, category_type: any) => {
                    return (item?.id !== undefined && (formik.values?.schedule as any)?.value !== 'now');
                },
                then: (schema) => Yup.string().required(`${t('Time is required')}`).when('category_type', {
                    is: (cateType: any) => {
                        return (formik.values?.category_type as any)?.value === 'exchange_rate';
                    },
                    then: (schema) => Yup.string().required(`${t('Time is required')}`).test('is-later-than-9-30', `${t('You can start writing articles from 9:30 AM!')}`, function (value) {
                        if (!value) return true;
                        const [hours, minutes] = value.split(':').map(Number);
                        const timeInMinutes = hours * 60 + minutes;
                        const nineThirtyInMinutes = 9 * 60 + 30;
                        return (timeInMinutes >= nineThirtyInMinutes);
                    }),
                }),
            }),
            publishing_date: Yup.object().shape({
                label: Yup.string(),
                value: Yup.string(),
            }).when('publishing_schedule', {
                is: (publishing_schedule: any) => (OPTIONS_MUST_HAVE_DATE?.includes((formik.values?.publishing_schedule as any)?.value)),
                then: (schema) => Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string(),
                }).required(`${t('Day is required')}`),
            }),
            publishing_time: Yup.string().when(['publishing_schedule', 'category_type'], {
                is: (publishing_schedule: any, category_type: any) => {
                    return (item?.id !== undefined && (formik.values?.publishing_schedule as any)?.value !== 'now');
                },
                then: (schema) => Yup.string().required(`${t('Time is required')}`).when('category_type', {
                    is: (cateType: any) => {
                        return (formik.values?.category_type as any)?.value === 'exchange_rate';
                    },
                    then: (schema) => Yup.string().required(`${t('Time is required')}`).test('is-later-than-9-30', `${t('You can start writing articles from 9:30 AM!')}`, function (value) {
                        if (!value) return true;
                        const [hours, minutes] = value.split(':').map(Number);
                        const timeInMinutes = hours * 60 + minutes;
                        const nineThirtyInMinutes = 9 * 60 + 30;
                        return (timeInMinutes >= nineThirtyInMinutes);
                    }),
                }),
            }),
            title_template_id: Yup.object().test('validate title', `${t('News Title Template is required')}`, function (value) {
                return !!value;
            }).required(`${t('News Title Template is required')}`),
            body_template_id: Yup.object().test('validate body', `${t('News Content Template is required')}`, function (value) {
                return !!value;
            }).required(`${t('News Content Template is required')}`),
            is_random_chart: Yup.boolean().nullable()
        })
    });


    const validationSchema = Yup.object({
        schedule: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).required(`${t("Creating Schedule is required")}`),
        publishing_schedule: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).required(`${t("Publishing Schedule is required")}`),
        sections: Yup.array().of(sectionSchema).min(1, `${t("At least 1 media is required")}`),
        category_type: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .required(`${t("Category Type is required")}`),
        category: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .when("category_type", {
                is: (cateType: any) => !!(OPTIONS_MUST_HAVE_CATEGORY_AND_KEYWORD?.includes(cateType?.value)),
                then: (schema) =>
                    Yup.object()
                        .shape({
                            label: Yup.string(),
                            value: Yup.string(),
                        })
                        .required(`${t("Category is required")}`),
                otherwise: (schema) =>
                    Yup.object()
                        .shape({
                            label: Yup.string(),
                            value: Yup.string(),
                        })
                        .nullable(),
            }),
        classification: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        keywords: Yup.array().when("category_type", {
            is: (cateType: any) => !!(cateType?.value === "default"),
            then: (schema) =>
                Yup.array()
                    .of(
                        Yup.object().shape({
                            label: Yup.string(),
                            value: Yup.string(),
                        })
                    )
                    .min(1, `${t("At least 1 keyword is required")}`)
                    .required("At least 1 keyword is required"),
            otherwise: (schema) => Yup.array().nullable(),
        }),
        tags: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.string(),
                    text: Yup.string(),
                })
            )
            .nullable(),
    });

    const formik = useFormik({
        initialValues: {
            schedule: null,
            publishing_schedule: null,
            sections: [],
            category_type: null,
            category: null,
            classification: null,
            keywords: null,
            tags: "",
            action_type: "",
        },
        validationSchema,
        onSubmit: handleShowConfirmCreateCampaign,
    });


    //@ts-ignore
    const initialValueCategoryType = (formik?.values && (formik?.values.category_type as unknown as Option)) || (listCategoriesTypeLang?.find((cate) => cate?.value === categoryTypeDefaultValue) || listCategoriesTypeLang[0]);

    //@ts-ignore
    const initialValueCategory = formik?.values?.category as Option;

    //@ts-ignore
    const initialValueClassification = formik?.values?.classification as Option;

    //@ts-ignore
    const initialValueKeywords = (formik?.values?.keywords as Option[]) || [];

    //@ts-ignore
    const initialValueSections = (formik?.values?.sections as Option[]) || [];

    //@ts-ignore
    const initialValueTags = (formik?.values?.tags as Tag[]) || [];

    //@ts-ignore
    const initialValueActionType = formik?.values?.action_type || ACTION_TYPE_CAMPAIGN.POST;

    const isDisabledPreview = (!initialValueCategory?.value && OPTIONS_MUST_HAVE_CATEGORY_AND_KEYWORD?.includes(initialValueCategoryType?.value))

    const LIST_SECTION: any = useMemo(() => {
        if (initialValueSections?.length === 0) {
            formik.setFieldValue(`sections`, [
                {
                    id: String(+ new Date()),
                    date: undefined,
                    time: '09:00',
                    publishing_date: undefined,
                    publishing_time: '09:00',
                    title_template_id: undefined,
                    body_template_id: undefined,
                    is_random_chart: false,
                    section_code: undefined,
                },
            ]);
        }
        return initialValueSections;
    }, [initialValueSections]);

    function onClosePreviewClick() {
        setIsOpenPreview((_prev) => !_prev);
    }

    const onSaveClick = () => {
        formik.setFieldValue("action_type", ACTION_TYPE_CAMPAIGN.SAVE);
        formik.submitForm();
    };

    const onPostClick = () => {
        formik.setFieldValue("action_type", ACTION_TYPE_CAMPAIGN.POST);
        formik.submitForm();
    };

    const handlePreviewTemplate = async ({ index, is_random_chart, title_template_id, body_template_id }: { index: number, is_random_chart: boolean, title_template_id: string | number, body_template_id: string | number }) => {
        try {
            setIsPreviewLoading((_prev) => index);
            const data = {
                category_type: initialValueCategoryType?.value,
                category_id: initialValueCategory?.value ?? null,
                is_random_chart,
                title_template_id,
                body_template_id,
                keyword_ids: [],
            };
            const response: any = await previewNews(data);
            if (response?.data) {
                setPreviewTemplate(
                    (_prev) => response?.data || { title: "", content: "" }
                );
                setIsPreviewLoading((_prev) => null);
                setIsOpenPreview((_prev) => true);
            } else {
                setIsPreviewLoading((_prev) => null);
                toast(`${response || ""}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsPreviewLoading((_prev) => null);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const isLoadFirstRef = useRef<any>(true);

    const handleSetValueHashtag = (value: any) => {
        if (!isLoadFirstRef.current || !id) {
            const vTags =
                ((value || [])?.map((item: any) => ({
                    id: item?.label,
                    text: item?.label,
                })) as Tag[]) || [];
            formik.setFieldValue("tags", vTags);
        }
        isLoadFirstRef.current = false;
    };

    const handleRemoveSection = (index: number, i: number) => {
        const sections = formik?.values?.sections || [];
        formik.setFieldValue(`sections`, sections?.filter((item: any, idx: number) => idx !== i));
    };

    const handleAddSection = (index: number) => {
        const sections = formik?.values?.sections || [];
        formik.setFieldValue(`sections`, [...sections, {
            id: String(+ new Date()),
            date: DATE_OPTIONS_LANG?.filter((e: any) => e.type === (formik.values?.schedule as any)?.value)[0],
            time: '09:00',
            publishing_date: DATE_OPTIONS_LANG?.filter((e: any) => e.type === (formik.values?.publishing_schedule as any)?.value)[0],
            publishing_time: '09:00',
            title_template_id: undefined,
            body_template_id: undefined,
            is_random_chart: false,
            section_code: undefined,
        },]);
    };

    const handleSetValueForm = (
        valueDefault: any,
        templates: ITemplate[] = listTemplate,
        medias: IMedia[] = listMedia,
    ) => {
        isLoadFirstRef.current = true;
        const vCategoryType = listCategoriesTypeLang?.filter(
            (e: any) => String(e.value) === String(valueDefault?.category_type)
        )[0];

        const vCategory = valueDefault?.category_id ? { label: "", value: valueDefault?.category_id } : null;

        const vClassification = listClassificationsLang?.filter((e: any) => (e.value === valueDefault?.classification))[0] || { label: t('----- None Classification -----'), value: '' };

        const vTags = valueDefault?.tag_related_news ? valueDefault?.tag_related_news
            ?.split(",")
            ?.map((item: string) => ({ id: item, text: item })) : [];

        const vSchedule = SCHEDULE_OPTIONS_LANG?.filter((e: any) => e.value === valueDefault?.schedule)[0];

        const vPublishingSchedule = SCHEDULE_OPTIONS_LANG?.filter((e: any) => e.value === valueDefault?.publishing_schedule)[0];


        const vSections = (valueDefault?.partners || [])?.reduce((arr: any = [], item: any, i: number) => {
            const partner = medias?.filter((m) => (String(m?.id) === String(item?.partner_id)))[0];
            const partnerCodes = partner?.partner_codes || [];
            const sectionCode = partnerCodes?.filter((e: any) => String(e.id) === String(item?.partner_code_id))[0];
            const optionTemplateTitle = templates?.filter((e: any) => String(e.id) === String(item?.title_template_id))[0];
            const optionTemplateBody = templates?.filter((e: any) => String(e.id) === String(item?.body_template_id))[0];
            const vSection = {
                id: String(+ new Date() + i),
                date: DATE_OPTIONS_LANG?.filter((e: any) => e.value === item?.date && e.type === item?.schedule)[0],
                time: item?.time,
                publishing_date: DATE_OPTIONS_LANG?.filter((e: any) => e.value === item?.publishing_date && e.type === item?.publishing_schedule)[0],
                publishing_time: item?.publishing_time,
                title_template_id: {
                    label: optionTemplateTitle?.name,
                    value: String(optionTemplateTitle?.id),
                },
                body_template_id: {
                    label: optionTemplateBody?.name,
                    value: String(optionTemplateBody?.id),
                },
                is_random_chart: !!item?.is_random_chart,
                media: {
                    value: String(partner?.id),
                    label: partner?.name || ''
                },
                section_code: item?.partner_code_id ? {
                    label: sectionCode?.section_title,
                    value: sectionCode?.id,
                } : undefined
            };
            return arr.concat([vSection]);
        }, []);

        setTimeout(() => {
            formik.setFieldValue("category_type", vCategoryType);
            formik.setFieldValue("category", vCategory);
            formik.setFieldValue('schedule', vSchedule);
            formik.setFieldValue('publishing_schedule', vPublishingSchedule);
            formik.setFieldValue('classification', vClassification);

        }, 300);
        setTimeout(() => {
            formik.setFieldValue("tags", vTags);
            formik.setFieldValue("sections", vSections)
        }, 400);
    };

    const handleCallAllOption = async (idItem: string) => {
        try {
            if (!idItem) {
                return
            }
            setIsLoadingDetail((_prev) => true);
            const [resDetail]: any = await Promise.all([idItem ? getDetailCampaign(idItem) : {},]);
            if (resDetail?.data) {
                handleSetValueForm(resDetail?.data);
                setIsLoadingDetail((_prev) => false);
            }
        } catch (error: any) {
            setIsLoadingDetail((_prev) => false);
            return error;
        }
    };

    useEffect(() => {
        handleCallAllOption(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (LIST_TYPE_CATEGORY_NOT_SCHEDULE.includes(typeCampaignForm)) {
            const categoryTypeDefault = listCategoriesTypeLang?.filter((item: any) => item?.value === String(typeCampaignForm).toLowerCase());
            formik.setFieldValue("category_type", categoryTypeDefault[0]);
            formik.setFieldValue("schedule", SCHEDULE_OPTIONS_LANG[0]);
            formik.setFieldValue("publishing_schedule", SCHEDULE_OPTIONS_LANG[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(listCategoriesTypeLang)]);

    useEffect(() => {
        document.title = `${t("Campaign Create")} - ${t("Campaign")} | NewsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            {isLoadingDetail && <div style={{
                position: 'absolute',
                zIndex: 3,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgb(164 164 164 / 36%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Spinner size="sm" color="primary"></Spinner>
            </div>}
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <Card id="leadsList">
                            <CardBody className="pt-3">
                                <Row className={`mt-1 g-5 ${isModal ? 'mb-0' : 'mb-5'}`}>
                                    {!isModal && <Col sm={0} md={0} xl={1} lg={1} className="m-0"></Col>}
                                    <Col sm={12} md={7} xl={isModal ? 7 : 6} lg={isModal ? 8 : 7} className={isModal ? 'mt-2' : ''}>
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-topbar-search-bg)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            {!LIST_TYPE_CATEGORY_NOT_SCHEDULE?.includes(typeCampaignForm) && (
                                                <>
                                                    <Col
                                                        sm={6}
                                                        md={6}
                                                        className="d-flex justify-content-between align-items-end"
                                                    >
                                                        <div style={{ width: "100%" }}>
                                                            <label className="form-label">
                                                                {t("Category Type")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <CategoryType
                                                                name="category_type"
                                                                dataList={listCategoriesTypeLang}
                                                                placeholder={`${t("Select Category Type")}...`}
                                                                initialValue={
                                                                    initialValueCategoryType ?? undefined
                                                                }
                                                                onChangeSelect={(event) => {
                                                                    formik.setFieldValue("category_type", event);
                                                                    formik.setFieldValue("category", null);
                                                                    formik.setFieldValue("keywords", null);
                                                                    formik.setFieldValue("classification", null);
                                                                }}
                                                                disabled={!!categoryTypeDefaultValue}
                                                            />
                                                            {formik.touched.category_type &&
                                                                formik.errors.category_type ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.category_type}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col sm={3} md={3}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Creating Schedule")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <ScheduleType
                                                                name="schedule"
                                                                id="schedule"
                                                                dataList={SCHEDULE_OPTIONS_LANG}
                                                                placeholder={`${t('Select Creating Schedule')}...`}
                                                                initialValue={formik.values?.schedule || SCHEDULE_OPTIONS_LANG[0]}
                                                                onChangeSelect={(event) => {
                                                                    formik.setFieldValue('schedule', event);
                                                                    formik.setFieldValue('publishing_schedule', event);
                                                                    ((formik.values?.sections as any) || [])?.map((item: any, index: number) => {
                                                                        const date = DATE_OPTIONS_LANG?.filter((e: any) => e.type === event?.value)[0];
                                                                        formik.setFieldValue(`sections[${index}]date`, date);
                                                                        formik.setFieldValue(`sections[${index}]publishing_date`, date);
                                                                        return item;
                                                                    })
                                                                }}
                                                            />
                                                            {formik.touched.schedule && formik.errors.schedule ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.schedule}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col sm={3} md={3}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Publishing Schedule")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <ScheduleType
                                                                name="publishing_schedule"
                                                                id="publishing_schedule"
                                                                dataList={SCHEDULE_OPTIONS_LANG}
                                                                placeholder={`${t('Select Publishing Schedule')}...`}
                                                                initialValue={formik.values?.publishing_schedule || SCHEDULE_OPTIONS_LANG[0]}
                                                                onChangeSelect={(event) => {
                                                                    formik.setFieldValue('publishing_schedule', event);
                                                                    ((formik.values?.sections as any) || [])?.map((item: any, index: number) => {
                                                                        const date = DATE_OPTIONS_LANG?.filter((e: any) => e.type === event?.value)[0];
                                                                        formik.setFieldValue(`sections[${index}]publishing_date`, date);
                                                                        return item;
                                                                    })
                                                                }}
                                                            />
                                                            {formik.touched.publishing_schedule && formik.errors.publishing_schedule ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.publishing_schedule}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col xxl={12}>
                                                        <div>
                                                            <label className="form-label required">
                                                                {t("Category")}
                                                                {OPTIONS_MUST_HAVE_CATEGORY_AND_KEYWORD?.includes(initialValueCategoryType?.value) && <span className="text-danger"> *</span>}
                                                            </label>
                                                            <DropdownCategoryKeyword
                                                                name="category"
                                                                keyValue="id"
                                                                categoryType={initialValueCategoryType?.value}
                                                                dataListNaver={listCategoriesNaver}
                                                                dataListStock={listCategoriesStock}
                                                                dataListCar={listCategoriesCar}
                                                                dataListCoin={listCategoriesCoin}
                                                                dataListRankify={listCategoriesRankify}
                                                                dataListTrendIndex={listCategoriesTrendIndex}
                                                                initialValue={initialValueCategory ?? undefined}
                                                                disabled={!OPTIONS_MUST_HAVE_CATEGORY_AND_KEYWORD?.includes(initialValueCategoryType?.value)}
                                                                onChangeCategory={(event) => {
                                                                    formik.setFieldValue("category", event);
                                                                }}
                                                            />
                                                            {formik.touched.category && formik.errors.category ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.category}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col xxl={12}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Classification")}{" "}
                                                            </label>
                                                            <DropdownOption
                                                                name="classification"
                                                                dataList={listClassificationsLang?.filter((op: any) => op?.type === (initialValueCategoryType as any)?.value) || []}
                                                                placeholder={`${t("Select Classification")}...`}
                                                                className="search-filter-classification-type"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={initialValueClassification ?? undefined}
                                                                onChangeSelect={(e: any) => formik.setFieldValue("classification", e)}
                                                                isHasOptionAll={true}
                                                                optionAll={{ label: t('----- None Classification -----'), value: '' }}
                                                            />
                                                            {formik.touched.classification && formik.errors.classification ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.classification}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </>
                                            )}
                                            {/* Begin::List Section */}
                                            <div className="p-4 mt-0">
                                                {(LIST_SECTION || [])?.map((item: any, i: number) => (
                                                    <Row key={item?.id}
                                                        className="mb-4 position-relative"
                                                        style={{
                                                            padding: '5px 0px',
                                                            borderRadius: '5px',
                                                            border: '1px dashed var(--vz-info)',
                                                            backgroundColor: 'var(--vz-light)',
                                                        }}
                                                    >
                                                        <div className="position-absolute w-auto end-0 top-0"
                                                            style={{
                                                                transform: 'translateY(-50%)',
                                                                padding: '5px 10px',
                                                                zIndex: 2,
                                                                fontWeight: 500
                                                            }}
                                                        >
                                                            <button
                                                                className="btn btn-primary btn-sm"
                                                                color="light"
                                                                type="button"
                                                                onClick={() => handlePreviewTemplate({
                                                                    index: i,
                                                                    is_random_chart: (formik?.values?.sections?.[i] as any)?.is_random_chart,
                                                                    title_template_id: (formik?.values?.sections?.[i] as any)?.title_template_id?.value,
                                                                    body_template_id: (formik?.values?.sections?.[i] as any)?.body_template_id?.value
                                                                })}
                                                                disabled={
                                                                    (isPreviewLoading !== null) ||
                                                                    isLoading ||
                                                                    isDisabledPreview ||
                                                                    !(formik?.values?.sections?.[i] as any)?.title_template_id?.value ||
                                                                    !(formik?.values?.sections?.[i] as any)?.body_template_id?.value
                                                                }
                                                            >
                                                                {(isPreviewLoading === i) ? (
                                                                    <Spinner size="sm" style={{ width: '13px', height: '13px' }}></Spinner>
                                                                ) : (
                                                                    <i className="ri-eye-fill align-bottom"></i>
                                                                )}
                                                            </button>
                                                            {(i > 0) && (<button
                                                                className="btn btn-sm btn-danger edit-item-btn ms-2"
                                                                type="button"
                                                                disabled={(isPreviewLoading !== null) || isLoading}
                                                                onClick={(e) => { e.preventDefault(); handleRemoveSection(item?.value, i) }}
                                                            >
                                                                <i className="ri-close-circle-fill align-bottom "></i>
                                                            </button>)}
                                                            {((formik.values?.sections as any)?.length === (i + 1)) && (<button
                                                                className="btn btn-sm btn-success edit-item-btn ms-2"
                                                                type="button"
                                                                disabled={(isPreviewLoading !== null) || isLoading}
                                                                onClick={(e) => { e.preventDefault(); handleAddSection(item?.value) }}
                                                            >
                                                                <i className="ri-add-circle-fill align-bottom "></i>
                                                            </button>)}
                                                        </div>
                                                        <Col sm={12} lg={6} className="mt-3">
                                                            <div>
                                                                <label className="form-label">
                                                                    {t("Media")}
                                                                    <span className="text-danger"> *</span>
                                                                </label>
                                                                <SearchFilterMedia
                                                                    name={`sections[${i}]media`}
                                                                    id={`sections[${i}]media`}
                                                                    dataList={listMedia}
                                                                    isMulti={false}
                                                                    initialValue={((formik.values?.sections?.[i] as any)?.media as unknown as Option) || undefined}
                                                                    onChangeSelect={(event: any) => {
                                                                        formik.setFieldValue(`sections[${i}]media`, event);
                                                                        const valSections = listMedia?.filter((m) => (String(m?.id) === String(event?.value)))[0]?.partner_codes || [];
                                                                        formik.setFieldValue(`sections[${i}]section_code`, valSections[0] ? ({
                                                                            label: valSections[0]?.section_title,
                                                                            value: String(valSections[0]?.id),
                                                                        }) : null);
                                                                    }}
                                                                />
                                                                {(formik.touched?.sections?.[i] as any) && (formik.errors?.sections?.[i] as any)?.media ? (
                                                                    <div className="text-danger mt-2">{(formik.errors?.sections?.[i] as any)?.media}</div>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col sm={12} lg={6} className="mt-3">
                                                            <label className="form-label">{t('Section Title')}</label>
                                                            <DropdownSectionCodeMediaType
                                                                name={`sections[${i}]section_code`}
                                                                dataList={listMedia?.filter((m) => (String(m?.id) === String((formik.values?.sections?.[i] as any)?.media?.value)))[0]?.partner_codes || []}
                                                                initialValue={(formik?.values?.sections?.[i] as any)?.section_code}
                                                                onChangeSelect={(event: any) => {
                                                                    formik.setFieldValue(`sections[${i}]section_code`, event)
                                                                }}
                                                            />
                                                            {(formik.touched?.sections?.[i] as any) && (formik.errors?.sections?.[i] as any)?.section_code ? (
                                                                <div className="text-danger mt-2">{(formik.errors?.sections?.[i] as any)?.section_code}</div>
                                                            ) : null}
                                                        </Col>

                                                        {(!LIST_TYPE_CATEGORY_NOT_SCHEDULE?.includes(typeCampaignForm) && OPTIONS_MUST_HAVE_PUBLISHING?.includes((formik.values?.schedule as any)?.value)) && (
                                                            <>
                                                                <Col sm={6} md={6} lg={6} className="mt-2">
                                                                    <div>
                                                                        <label className="form-label">
                                                                            {t("Creating Date")}{" "}
                                                                            {OPTIONS_MUST_HAVE_DATE?.includes((formik.values?.schedule as any)?.value) && <span className="text-danger"> *</span>}
                                                                        </label>
                                                                        <Select
                                                                            options={DATE_OPTIONS_LANG?.filter((op) => op?.type === (formik.values?.schedule as any)?.value) || []}
                                                                            value={
                                                                                !OPTIONS_MUST_HAVE_DATE?.includes((formik.values?.schedule as any)?.value)
                                                                                    ? null
                                                                                    : (formik.values?.sections?.[i] as any)?.date
                                                                            }
                                                                            name={`sections[${i}]date`}
                                                                            id={`sections[${i}]date`}
                                                                            autocomplete={false}
                                                                            isDisabled={
                                                                                !OPTIONS_MUST_HAVE_DATE?.includes((formik.values?.schedule as any)?.value)
                                                                            }
                                                                            placeholder={`${t("Select Creating Date")}...`}
                                                                            className="select-schedule"
                                                                            classNamePrefix="name-prefix"
                                                                            onChange={(event: any) => {
                                                                                formik.setFieldValue(`sections[${i}]date`, event);
                                                                                formik.setFieldValue(`sections[${i}]publishing_date`, event);
                                                                            }}
                                                                        ></Select>
                                                                        {(formik.touched?.sections?.[i] as any) && (formik.errors?.sections?.[i] as any)?.date ? (
                                                                            <div className="text-danger mt-2">{(formik.errors?.sections?.[i] as any)?.date}</div>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                                <Col sm={6} md={6} lg={6} className="mt-2">
                                                                    <div >
                                                                        <label className="form-label">
                                                                            {t("Creating Time")}{" "}
                                                                            {(formik.values?.schedule as any)?.value !== "now" && (
                                                                                <span className="text-danger"> *</span>
                                                                            )}
                                                                        </label>
                                                                        <div className="date-picker-wrapper-custom">
                                                                            <DatePicker
                                                                                className="form-control search"
                                                                                placeholderText={`${t("Select Creating Time")}...`}
                                                                                showTimeSelect
                                                                                showTimeSelectOnly
                                                                                disabled={((formik.values?.schedule as any)?.value === "now")}
                                                                                timeIntervals={5}
                                                                                autoComplete="off"
                                                                                isClearable={true}
                                                                                timeCaption={t("Time")}
                                                                                timeFormat="HH:mm"
                                                                                value={
                                                                                    (formik.values?.schedule as any)?.value === "now" ? "" : (formik?.values?.sections?.[i] as any)?.time
                                                                                }
                                                                                locale={i18n?.language === "ko" ? "ko" : "en"}
                                                                                name={`sections[${i}]time`}
                                                                                onChange={(value: Date | null) => {
                                                                                    formik.setFieldValue(`sections[${i}]time`, value ? moment(new Date(value)).format("HH:mm") : "");
                                                                                    formik.setFieldValue(`sections[${i}]publishing_time`, value ? moment(new Date(value)).format("HH:mm") : "");
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {(formik.touched?.sections?.[i] as any) && (formik.errors?.sections?.[i] as any)?.time ? (
                                                                            <div className="text-danger mt-2">{(formik.errors?.sections?.[i] as any)?.time}</div>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                            </>
                                                        )}
                                                        {/* Begin::Publishing */}
                                                        {OPTIONS_MUST_HAVE_PUBLISHING?.includes((formik.values?.publishing_schedule as any)?.value) && (
                                                            <>
                                                                <Col sm={6} md={6} lg={6} className="mt-3">
                                                                    <div>
                                                                        <label className="form-label">
                                                                            {t("Publishing Date")}{" "}
                                                                            {OPTIONS_MUST_HAVE_DATE?.includes((formik.values?.publishing_schedule as any)?.value) && <span className="text-danger"> *</span>}
                                                                        </label>
                                                                        <Select
                                                                            options={DATE_OPTIONS_LANG?.filter((op) => op?.type === (formik.values?.publishing_schedule as any)?.value) || []}
                                                                            value={
                                                                                !OPTIONS_MUST_HAVE_DATE?.includes((formik.values?.publishing_schedule as any)?.value)
                                                                                    ? null
                                                                                    : (formik.values?.sections?.[i] as any)?.publishing_date
                                                                            }
                                                                            name={`sections[${i}]publishing_date`}
                                                                            id={`sections[${i}]publishing_date`}
                                                                            autocomplete={false}
                                                                            isDisabled={
                                                                                !OPTIONS_MUST_HAVE_DATE?.includes((formik.values?.publishing_schedule as any)?.value)
                                                                            }
                                                                            placeholder={`${t("Select Publishing Date")}...`}
                                                                            className="select-schedule"
                                                                            classNamePrefix="name-prefix"
                                                                            onChange={(event: any) => {
                                                                                formik.setFieldValue(`sections[${i}]publishing_date`, event)
                                                                            }}
                                                                        ></Select>
                                                                        {(formik.touched?.sections?.[i] as any) && (formik.errors?.sections?.[i] as any)?.publishing_date ? (
                                                                            <div className="text-danger mt-2">{(formik.errors?.sections?.[i] as any)?.publishing_date}</div>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                                <Col sm={6} md={6} lg={6} className="mt-3">
                                                                    <div>
                                                                        <label className="form-label">
                                                                            {t("Publishing Time")}{" "}
                                                                            {(formik.values?.publishing_schedule as any)?.value !== "now" && (
                                                                                <span className="text-danger"> *</span>
                                                                            )}
                                                                        </label>
                                                                        <div className="date-picker-wrapper-custom">
                                                                            <DatePicker
                                                                                className="form-control search"
                                                                                placeholderText={`${t("Select Publishing Time")}...`}
                                                                                showTimeSelect
                                                                                showTimeSelectOnly
                                                                                disabled={((formik.values?.publishing_schedule as any)?.value === "now")}
                                                                                timeIntervals={5}
                                                                                autoComplete="off"
                                                                                isClearable={true}
                                                                                timeCaption={t("Time")}
                                                                                timeFormat="HH:mm"
                                                                                value={
                                                                                    (formik.values?.publishing_schedule as any)?.value === "now" ? "" : (formik?.values?.sections?.[i] as any)?.publishing_time
                                                                                }
                                                                                locale={i18n?.language === "ko" ? "ko" : "en"}
                                                                                name={`sections[${i}]publishing_time`}
                                                                                onChange={(value: Date | null) =>
                                                                                    formik.setFieldValue(
                                                                                        `sections[${i}]publishing_time`,
                                                                                        value
                                                                                            ? moment(new Date(value)).format(
                                                                                                "HH:mm"
                                                                                            )
                                                                                            : ""
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                        {(formik.touched?.sections?.[i] as any) && (formik.errors?.sections?.[i] as any)?.publishing_time ? (
                                                                            <div className="text-danger mt-2">{(formik.errors?.sections?.[i] as any)?.publishing_time}</div>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                            </>
                                                        )}
                                                        {/* End::Publishing */}
                                                        <Col sm={12} lg={12} className="d-flex justify-content-between align-items-end mt-3">
                                                            <div style={{ width: "100%" }}>
                                                                <label className="form-label">
                                                                    {t("Title Template")}
                                                                    <span className="text-danger"> *</span>
                                                                </label>
                                                                <SearchTemplate
                                                                    name={`sections[${i}]title_template_id`}
                                                                    templates={listTemplate}
                                                                    type="title"
                                                                    filterByCategoryType={templateCategoryDefaultValue || initialValueCategoryType?.value}
                                                                    filterBySchedule={(formik.values?.schedule as any)?.value}
                                                                    placeholder={`${t("Select Title Template")}...`}
                                                                    initialValue={(formik?.values?.sections?.[i] as any)?.title_template_id}
                                                                    onChangeSelect={(event) => {
                                                                        formik.setFieldValue(
                                                                            `sections[${i}]title_template_id`,
                                                                            event
                                                                        );
                                                                    }}
                                                                />
                                                                {(formik.touched?.sections?.[i] as any) && (formik.errors?.sections?.[i] as any)?.title_template_id ? (
                                                                    <div className="text-danger mt-2">{(formik.errors?.sections?.[i] as any)?.title_template_id}</div>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col sm={12} lg={12} className="d-flex justify-content-between align-items-end mt-3 mb-3">
                                                            <div style={{ width: "100%" }}>
                                                                <label className="form-label">
                                                                    {t("Content Template")}
                                                                    <span className="text-danger"> *</span>
                                                                </label>
                                                                <SearchTemplate
                                                                    name={`sections[${i}]body_template_id`}
                                                                    templates={listTemplate}
                                                                    type="body"
                                                                    filterByCategoryType={templateCategoryDefaultValue || initialValueCategoryType?.value}
                                                                    filterBySchedule={(formik.values?.schedule as any)?.value}
                                                                    placeholder={`${t(
                                                                        "Select Content Template"
                                                                    )}...`}
                                                                    initialValue={(formik?.values?.sections?.[i] as any)?.body_template_id}
                                                                    onChangeSelect={(event) => {
                                                                        formik.setFieldValue(
                                                                            `sections[${i}]body_template_id`,
                                                                            event
                                                                        );
                                                                    }}
                                                                />
                                                                {(formik.touched?.sections?.[i] as any) && (formik.errors?.sections?.[i] as any)?.body_template_id ? (
                                                                    <div className="text-danger mt-2">{(formik.errors?.sections?.[i] as any)?.body_template_id}</div>
                                                                ) : null}
                                                                <div className=" w-100 form-check mt-2">
                                                                    <Input
                                                                        className="form-check-input"
                                                                        name={`sections[${i}]is_random_chart`}
                                                                        checked={(formik?.values?.sections?.[i] as any)?.is_random_chart}
                                                                        type="checkbox"
                                                                        id={`sections[${i}]is_random_chart`}
                                                                        onChange={(event: any) => formik.setFieldValue(`sections[${i}]is_random_chart`, Boolean(event?.target.checked))}
                                                                    />
                                                                    <Label className="form-check-label text-muted" style={{ fontSize: '12px' }} htmlFor={`sections[${i}]is_random_chart`}>{t('Randomly apply infographic charts')}</Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </div>
                                            {/* End::List Section */}

                                        </div>
                                    </Col>
                                    <Col sm={12} md={5} xl={isModal ? 5 : 4} lg={isModal ? 4 : 3} className={isModal ? 'mt-2' : ''}>
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-topbar-search-bg)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            {!LIST_TYPE_CATEGORY_NOT_SCHEDULE?.includes(typeCampaignForm) && (<Col xxl={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Keywords")}
                                                        {OPTIONS_MUST_HAVE_CATEGORY_AND_KEYWORD?.includes(initialValueCategoryType?.value) && <span className="text-danger"> *</span>}
                                                    </label>
                                                    <SearchFilterKeyword
                                                        disabled={true}
                                                        name="keywords"
                                                        isGetListOptionDefault={false}
                                                        initialValue={initialValueKeywords || []}
                                                        categoryType={
                                                            initialValueCategoryType?.value || "default"
                                                        }
                                                        filterByCategory={String(
                                                            initialValueCategory?.value || ""
                                                        )}
                                                        onChangeSelect={(event) => {
                                                            formik.setFieldValue("keywords", event);
                                                            handleSetValueHashtag &&
                                                                handleSetValueHashtag(event);
                                                        }}
                                                        maxKeyword={20}
                                                    />
                                                    {formik.touched.keywords &&
                                                        formik.errors.keywords ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.keywords}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>)}
                                            <Col xxl={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Hashtags")}
                                                    </label>
                                                    <Hashtag
                                                        name="tags"
                                                        placeholder={`${t("Hashtags")}...`}
                                                        suggestions={[]}
                                                        initialValue={initialValueTags || []}
                                                        onChangeTag={(event) => {
                                                            formik.setFieldValue("tags", event);
                                                        }}
                                                        idSelected="hashtags-CampaignFormMulti"
                                                        maxHashtags={20}
                                                        typeShowMore={TYPE_SHOW_MORE_HASHTAG.SCROLL}
                                                        maxHeight="150px"
                                                    />
                                                    {formik.touched.tags && formik.errors.tags ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.tags}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <div className="col-lg-12">
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3 mb-4">
                                                    {isHavePermissionRole([ROLES_FOR_APP.CAMPAIGN_CREATE, ROLES_FOR_APP.CAMPAIGN_SET_CAMPAIGN_SPORT], userPermissions) && (
                                                        <>
                                                            <button
                                                                className="btn btn-success fs-14"
                                                                color="light"
                                                                type="button"
                                                                onClick={onSaveClick}
                                                                disabled={isLoading || (isPreviewLoading !== null)}
                                                            >
                                                                {isLoading &&
                                                                    initialValueActionType ===
                                                                    ACTION_TYPE_CAMPAIGN.SAVE ? (
                                                                    <Spinner size="sm" className="me-2"></Spinner>
                                                                ) : (
                                                                    <i className="ri-login-circle-line align-bottom me-1"></i>
                                                                )}
                                                                {t("Button Save")}
                                                            </button>
                                                            <button
                                                                className="btn btn-primary fs-14"
                                                                color="success"
                                                                type="button"
                                                                onClick={onPostClick}
                                                                disabled={isLoading || (isPreviewLoading !== null)}
                                                            >
                                                                {isLoading &&
                                                                    initialValueActionType ===
                                                                    ACTION_TYPE_CAMPAIGN.POST ? (
                                                                    <Spinner size="sm" className="me-2"></Spinner>
                                                                ) : (
                                                                    <i className="ri-send-plane-line align-bottom me-1"></i>
                                                                )}
                                                                {t("Button Post")}
                                                            </button>
                                                        </>)}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    {!isModal && <Col sm={0} md={0} xl={1} lg={1} className="m-0"></Col>}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </form>
            <ToastContainer closeButton={false} limit={1} />
            <Modal
                isOpen={isOpenPreview}
                centered={true}
                size="xl"
                toggle={onClosePreviewClick}
                keyboard={true}
                scrollable={true}

            >
                <ModalHeader toggle={isPreviewLoading ? () => { } : onClosePreviewClick} >
                    {t("Template Preview")}
                </ModalHeader>
                <ModalBody>
                    <div className="row g-4">

                        <Col md={12}>
                            <div>
                                <div className="row g-3 ">
                                    <Col xxl={12} className="ribbon-box right">
                                        {!!previewTemplate?.memo?.errors?.length && (
                                            <div className="ribbon ribbon-danger round-shape top-0" style={{
                                                zIndex: 9,
                                                background: 'rgb(255 255 255 / 67%)',
                                                color: '#f06548',
                                                borderRadius: 0,
                                                maxHeight: '30dvh',
                                                overflowY: 'auto',
                                            }}>
                                                <span style={{ fontSize: '12px', lineHeight: '12px', fontWeight: 'normal' }}>{(previewTemplate?.memo?.errors || [])?.map((mess: any) => (
                                                    <>{`${mess}`}<br /> </>
                                                ))}
                                                </span>
                                            </div>
                                        )}
                                        <div className="bg-primary-subtle position-relative">
                                            <div className="p-5 card-body ">
                                                <div className="text-center ">
                                                    <h3 className="fw-semibold">
                                                        {previewTemplate?.title || ""}
                                                    </h3>
                                                </div>
                                            </div>

                                            <div className="shape">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    version="1.1"
                                                    width="1440"
                                                    height="60"
                                                    preserveAspectRatio="none"
                                                    viewBox="0 0 1440 60"
                                                >
                                                    <g mask='url("#SvgjsMask1001")' fill="none">
                                                        <path
                                                            d="M 0,4 C 144,13 432,48 720,49 C 1008,50 1296,17 1440,9L1440 60L0 60z"
                                                            style={{ fill: "var(--vz-secondary-bg)" }}
                                                        ></path>
                                                    </g>
                                                    <defs>
                                                        <mask id="SvgjsMask1001">
                                                            <rect
                                                                width="1440"
                                                                height="60"
                                                                fill="#ffffff"
                                                            ></rect>
                                                        </mask>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={12}>
                                        <div
                                            className="clear-css-p-in-box"
                                            dangerouslySetInnerHTML={{
                                                __html: previewTemplate?.content || "",
                                            }}
                                        ></div>
                                    </Col>
                                    <div className="col-lg-12">
                                        <div className="hstack gap-2 justify-content-end">
                                            <button
                                                className="btn btn-light fs-14"
                                                type="button"
                                                color="light"
                                                onClick={onClosePreviewClick}
                                            >
                                                <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                                                {t("Button Close")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>
                </ModalBody>
            </Modal>
            <ModalConfirm
                textButtonConfirm={t('Button Post')}
                classButtonConfirm='btn-primary'
                classIconButtonConfirm='ri-send-plane-line'
                header={t('Campaign Create')}
                title={t('Are you sure you want to post this?')}
                content={t('After confirming the post action, the data will be created. Do you want to proceed with the confirming action.')}
                isShowIcon={false}
                isOpen={isOpenConfirmCreate}
                onClose={onCloseConfirmCreateClick}
                onConfirm={handleCreateCampaign}
            />
        </React.Fragment >
    );
};

export default CampaignFormMulti;