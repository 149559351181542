import { ICategory } from "api/types/_category";
import { IMedia, IMediaCode, IType } from "api/types/_media";
import { IPreviewNews } from "api/types/_news";
import { Option } from "api/types/_public";
import { ITemplate } from "api/types/_template";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import CategoryType from "components/Common/CategoryType";
import DropdownCategoryKeyword from "components/Common/DropdownCategoryKeyword";
import DropdownOption from "components/Common/DropdownOption";
import DropdownSectionCodeMediaType from "components/Common/DropdownSectionCodeMedia";
import Hashtag from "components/Common/Hashtag";
import ModalConfirm from "components/Common/ModalConfirm";
import SearchFilterKeyword from "components/Common/SearchFilterKeyword";
import SearchFilterMedia from "components/Common/SearchFilterMedia";
import SearchTemplate from "components/Common/SearchTemplate";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import {
    ACTION_TYPE_CAMPAIGN,
    SCHEDULE_MONTHLY_OPTIONS,
    SCHEDULE_OPTIONS,
    SCHEDULE_WEEKLY_OPTIONS,
    TYPE_SHOW_MORE_HASHTAG
} from "helpers/constans";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Card,
    CardBody,
    Col,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import {
    getDetailCampaign,
    postCampaign,
    previewNews,
    putCampaign
} from "store/thunks";
import * as Yup from "yup";

export interface Tag {
    id: string;
    text: string;
}

export interface Props {
    typeCampaignForm?: string;
    templateCategoryDefaultValue?: string | string[],
    isModal?: boolean;
    id?: string;
    isCopy?: boolean,
    listType: IType[],
    listMedia: IMedia[],
    listTemplate: ITemplate[],
    listCategoriesType: Option[],
    listClassifications?: Option[],
    listCategoriesNaver?: ICategory[],
    listCategoriesStock?: ICategory[],
    listCategoriesCar?: ICategory[],
    listCategoriesCoin?: ICategory[],
    listCategoriesTrendIndex?: ICategory[],
    listCategoriesRankify?: ICategory[],
    triggerRefresh?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const CampaignFormEdit = ({
    typeCampaignForm = '',
    templateCategoryDefaultValue = '',
    isModal = false,
    id = '',
    isCopy = false,
    listType = [],
    listMedia = [],
    listTemplate = [],
    listCategoriesType = [],
    listClassifications = [],
    listCategoriesNaver = [],
    listCategoriesStock = [],
    listCategoriesCar = [],
    listCategoriesCoin = [],
    listCategoriesTrendIndex = [],
    listCategoriesRankify = [],
    triggerRefresh,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    // const { id = "" } = useParams();

    const SCHEDULE_OPTIONS_LANG = SCHEDULE_OPTIONS?.map((item: any) => ({
        value: item?.value,
        label: t(item?.label),
    }));

    const OPTIONS_MUST_HAVE_DATE = ["weekly", "monthly"];

    const OPTIONS_MUST_HAVE_TIME = ["daily", "weekly", "monthly"];

    const OPTIONS_MUST_HAVE_CATEGORY_AND_KEYWORD = ["trend_index", "stock", "car", "coin", "dc_trend", "press_ai"];

    const LIST_TYPE_CATEGORY_NOT_SCHEDULE = ['SPORT', 'LOTTO'];

    const CATEGORY_TYPE_CREATE_BY_FORM_NORMAL = LIST_TYPE_CATEGORY_NOT_SCHEDULE?.includes(typeCampaignForm) ? [String(typeCampaignForm).toLowerCase()] : ['trend_index', 'exchange_rate', 'car', 'movie', 'stock', 'coin', 'dc_trend', 'press_ai'];

    const MONTHLY_OPTIONS = SCHEDULE_MONTHLY_OPTIONS?.map((item: Option) => ({ ...item, type: "monthly", })) || [];
    const WEEKLY_OPTIONS = SCHEDULE_WEEKLY_OPTIONS?.map((item: Option) => ({ ...item, type: "weekly", })) || [];
    const DATE_OPTIONS_LANG = MONTHLY_OPTIONS.concat(WEEKLY_OPTIONS)?.map((item: any) => ({ ...item, label: t(item?.label) }));

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);

    const [isOpenPreview, setIsOpenPreview] = useState<boolean>(false);

    const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(false);

    const [previewTemplate, setPreviewTemplate] = useState<IPreviewNews | null>(null);

    const listCategoriesTypeLang = listCategoriesType?.reduce((arr: any, item: any) => {
        if (CATEGORY_TYPE_CREATE_BY_FORM_NORMAL?.includes(item?.value)) {
            return ([...arr, ({
                ...item,
                label: t(`Category_Type_[${item?.value}]_For_Campaign`),
            })]);
        }
        return arr;
    }, []) || [];

    const [isOpenConfirmCreate, setIsConfirmCreate] = useState<boolean>(false);

    const handleShowConfirmCreateCampaign = () => {
        if (formik?.values?.action_type === ACTION_TYPE_CAMPAIGN.SAVE) {
            handleSubmit(formik.values);
        } else {
            setIsConfirmCreate((_prev) => true);
        }
    };

    const handleCreateCampaign = () => {
        setIsConfirmCreate((_prev) => false);
        handleSubmit(formik.values);
    }

    const onCloseConfirmCreateClick = () => {
        setIsConfirmCreate((_prev) => false);
    }


    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                name: values?.name ?? "",
                partner_id: values?.medias?.map((item: Option) => item?.value) || [],
                category_id: values?.category?.value ? String(values?.category?.value) : null,
                ...((id && !isCopy) ? {} : { classification: values?.classification?.value || null, }),
                type: values?.category_type?.value,
                keywords: [],
                schedule: values?.schedule?.value || "now",
                publishing_schedule: values?.publishing_schedule?.value || "now",
                time: values?.schedule?.value === "now" ? "" : values?.time || "",
                date: OPTIONS_MUST_HAVE_DATE.includes(values?.schedule?.value) ? values?.date?.value || "" : "",
                publishing_time: values?.publishing_schedule?.value === "now" ? "" : values?.publishing_time || "",
                publishing_date: OPTIONS_MUST_HAVE_DATE.includes(values?.publishing_schedule?.value) ? values?.publishing_date?.value || "" : "",
                tag_related_news: (values?.tags || [])?.map((item: Tag) => item?.id).join(",") || "",
                title_template_id: values?.title_template_id?.value ?? "",
                body_template_id: values?.body_template_id?.value ?? "",
                is_random_chart: values?.is_random_chart || false,
                action_type: values?.action_type || ACTION_TYPE_CAMPAIGN.POST,
                partner_codes: (values?.sections || [])?.reduce((arr: any, item: { id: string, section_code: IMediaCode }) => {
                    return item?.id ? [...arr, { partner_id: item?.id, partner_code_id: item?.section_code?.value || '' }] : arr;
                }, []) || [],

            };
            const response: any = (id && !isCopy) ? await putCampaign(id, data) : await postCampaign(data);
            if (response?.data) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                if (id) {
                    triggerRefresh && triggerRefresh();
                } else {
                    navigate("/campaign/list");
                }
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const sectionSchema = Yup.object().shape({
        id: Yup.string(),
        section_code: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
    });

    const validationSchema = Yup.object({
        name: Yup.string().required(`${t("Name is required")}`),
        types: Yup.object().nullable(),
        medias: Yup.array()
            .of(
                Yup.object().shape({
                    label: Yup.string().required(`${t("Please provide a id")}`),
                    value: Yup.string().required(`${t("Please provide a text")}`),
                })
            )
            .min(1, `${t("At least 1 media is required")}`),
        sections: Yup.array().of(sectionSchema).nullable(),
        category_type: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .required(`${t("Category Type is required")}`),
        category: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .when("category_type", {
                is: (cateType: any) => !!(OPTIONS_MUST_HAVE_CATEGORY_AND_KEYWORD?.includes(cateType?.value)),
                then: (schema) =>
                    Yup.object()
                        .shape({
                            label: Yup.string(),
                            value: Yup.string(),
                        })
                        .required(`${t("Category is required")}`),
                otherwise: (schema) =>
                    Yup.object()
                        .shape({
                            label: Yup.string(),
                            value: Yup.string(),
                        })
                        .nullable(),
            }),
        classification: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        keywords: Yup.array().when("category_type", {
            is: (cateType: any) => !!(cateType?.value === "default"),
            then: (schema) =>
                Yup.array()
                    .of(
                        Yup.object().shape({
                            label: Yup.string(),
                            value: Yup.string(),
                        })
                    )
                    .min(1, `${t("At least 1 keyword is required")}`)
                    .required("At least 1 keyword is required"),
            otherwise: (schema) => Yup.array().nullable(),
        }),
        schedule: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
        publishing_schedule: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
        date: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .when("schedule", {
                is: (schedule: any) =>
                    OPTIONS_MUST_HAVE_DATE?.includes(schedule?.value),
                then: (schema) =>
                    Yup.object()
                        .shape({
                            label: Yup.string(),
                            value: Yup.string(),
                        })
                        .required(`${t("Date is required")}`),
            }),
        time: Yup.string().when("schedule", {
            is: (schedule: any) => schedule?.value !== "now",
            then: (schema) =>
                Yup.string()
                    .required(`${t("Time is required")}`)
                    .when("category_type", {
                        is: (cateType: any) => cateType?.value === "exchange_rate",
                        then: (schema) =>
                            Yup.string().test(
                                "is-later-than-9-30",
                                `${t("You can start writing articles from 9:30 AM!")}`,
                                function (value) {
                                    if (!value) return true;
                                    const [hours, minutes] = value.split(":").map(Number);
                                    const timeInMinutes = hours * 60 + minutes;
                                    const nineThirtyInMinutes = 9 * 60 + 30;
                                    return timeInMinutes >= nineThirtyInMinutes;
                                }
                            ),
                    }),
        }),
        publishing_date: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .when("publishing_schedule", {
                is: (publishing_schedule: any) =>
                    OPTIONS_MUST_HAVE_DATE?.includes(publishing_schedule?.value),
                then: (schema) =>
                    Yup.object()
                        .shape({
                            label: Yup.string(),
                            value: Yup.string(),
                        })
                        .required(`${t("Date is required")}`),
            }),
        publishing_time: Yup.string().when("publishing_schedule", {
            is: (publishing_schedule: any) => publishing_schedule?.value !== "now",
            then: (schema) =>
                Yup.string()
                    .required(`${t("Time is required")}`)
                    .when("category_type", {
                        is: (cateType: any) => cateType?.value === "exchange_rate",
                        then: (schema) =>
                            Yup.string().test(
                                "is-later-than-9-30",
                                `${t("You can start writing articles from 9:30 AM!")}`,
                                function (value) {
                                    if (!value) return true;
                                    const [hours, minutes] = value.split(":").map(Number);
                                    const timeInMinutes = hours * 60 + minutes;
                                    const nineThirtyInMinutes = 9 * 60 + 30;
                                    return timeInMinutes >= nineThirtyInMinutes;
                                }
                            ),
                    }),
        }),
        title_template_id: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .required(`${t("News Title Template is required")}`),
        body_template_id: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .required(`${t("News Content Template is required")}`),
        is_random_chart: Yup.boolean().nullable(),
        tags: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.string(),
                    text: Yup.string(),
                })
            )
            .nullable(),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            types: null,
            medias: [],
            sections: [],
            category_type: null,
            classification: null,
            category: null,
            keywords: null,
            schedule: null,
            publishing_schedule: null,
            time: '09:00',
            date: null,
            publishing_time: '09:00',
            publishing_date: null,
            title_template_id: null,
            body_template_id: null,
            is_random_chart: false,
            tags: "",
            action_type: "",

        },
        validationSchema,
        onSubmit: handleShowConfirmCreateCampaign,
    });

    //@ts-ignore
    const initialValueNameCampaign = formik?.values?.name as Option;

    //@ts-ignore
    const initialValueCategoryType =
        (formik?.values && (formik?.values.category_type as unknown as Option)) || listCategoriesTypeLang[0];

    //@ts-ignore
    const initialValueCategory = formik?.values?.category as Option;

    //@ts-ignore
    const initialValueClassification = formik?.values?.classification as Option;

    //@ts-ignore
    const initialValueTitleTemplate = formik?.values?.title_template_id as Option;

    //@ts-ignore
    const initialValueBodyTemplate = formik?.values?.body_template_id as Option;

    //@ts-ignore
    const initialValueRandomInfographic = formik?.values?.is_random_chart as Option;

    //@ts-ignore
    const initialValueKeywords = (formik?.values?.keywords as Option[]) || [];

    //@ts-ignore
    const initialValueMedias = (formik?.values?.medias as Option[]) || [];

    //@ts-ignore
    const initialValueSchedule =
        (formik?.values?.schedule as unknown as Option) || SCHEDULE_OPTIONS_LANG[0];

    //@ts-ignore
    const initialValueDate =
        (formik?.values?.date as unknown as Option) || DATE_OPTIONS_LANG[0];


    //@ts-ignore
    const initialValuePublishingSchedule =
        (formik?.values?.publishing_schedule as unknown as Option) || SCHEDULE_OPTIONS_LANG[0];

    //@ts-ignore
    const initialValuePublishingDate =
        (formik?.values?.publishing_date as unknown as Option) || DATE_OPTIONS_LANG[0];

    //@ts-ignore
    const initialValueTags = (formik?.values?.tags as Tag[]) || [];

    //@ts-ignore
    const initialValueActionType =
        formik?.values?.action_type || ACTION_TYPE_CAMPAIGN.POST;

    const isDisabledPreview =
        (!initialValueCategory?.value && OPTIONS_MUST_HAVE_CATEGORY_AND_KEYWORD?.includes(initialValueCategoryType?.value)) ||
        !initialValueTitleTemplate?.value ||
        !initialValueBodyTemplate?.value;

    function onClosePreviewClick() {
        setIsOpenPreview((_prev) => !_prev);
    }

    const onSaveClick = () => {
        formik.setFieldValue("action_type", ACTION_TYPE_CAMPAIGN.SAVE);
        formik.submitForm();
    };

    const onPostClick = () => {
        formik.setFieldValue("action_type", ACTION_TYPE_CAMPAIGN.POST);
        formik.submitForm();
    };

    const onPreviewClick = async () => {
        handlePreviewTemplate();
    };

    const handlePreviewTemplate = async () => {
        try {
            setIsPreviewLoading((_prev) => true);
            const data = {
                category_type: initialValueCategoryType?.value,
                category_id: initialValueCategory?.value ?? null,
                title_template_id: initialValueTitleTemplate?.value,
                body_template_id: initialValueBodyTemplate?.value,
                is_random_chart: initialValueRandomInfographic,
                keyword_ids: [],

            };
            const response: any = await previewNews(data);
            if (response?.data) {
                setPreviewTemplate(
                    (_prev) => response?.data || { title: "", content: "" }
                );
                setIsPreviewLoading((_prev) => false);
                setIsOpenPreview((_prev) => true);
            } else {
                setIsPreviewLoading((_prev) => false);
                toast(`${response || ""}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsPreviewLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const isLoadFirstRef = useRef<any>(true);

    const handleSetValueHashtag = (value: any) => {
        if (!isLoadFirstRef.current || !id) {
            const vTags =
                ((value || [])?.map((item: any) => ({
                    id: item?.label,
                    text: item?.label,
                })) as Tag[]) || [];
            formik.setFieldValue("tags", vTags);
        }
        isLoadFirstRef.current = false;
    };

    const handleSetValueForm = (
        valueDefault: any,
        templates: ITemplate[] = listTemplate
    ) => {
        isLoadFirstRef.current = true;
        const optionTemplateTitle = templates?.filter(
            (e: any) => String(e.id) === String(valueDefault?.title_template_id)
        )[0];
        const optionTemplateBody = templates?.filter(
            (e: any) => String(e.id) === String(valueDefault?.body_template_id)
        )[0];

        const vRandomInfographic = !!valueDefault?.is_random_chart;

        const vName = valueDefault?.name;
        const vCategoryType = listCategoriesTypeLang?.filter(
            (e: any) => String(e.value) === String(valueDefault?.category_type)
        )[0];
        const vCategory = valueDefault?.category_id ? { label: "", value: valueDefault?.category_id } : null;

        const vClassification = listClassifications?.filter((e: any) => (e.value === valueDefault?.classification))[0] || { label: t('----- None Classification -----'), value: '' };

        const vSchedule = SCHEDULE_OPTIONS_LANG?.filter(
            (e: any) => e.value === valueDefault?.schedule
        )[0];
        const vTime = isCopy ? '09:00' : valueDefault?.time;
        const vDate = DATE_OPTIONS_LANG?.filter(
            (e: any) =>
                e.value === valueDefault?.date && e.type === valueDefault?.schedule
        )[0];

        const vPublishingSchedule = SCHEDULE_OPTIONS_LANG?.filter(
            (e: any) => e.value === valueDefault?.publishing_schedule
        )[0];
        const vPublishingTime = isCopy ? '09:00' : valueDefault?.publishing_time;
        const vPublishingDate = DATE_OPTIONS_LANG?.filter(
            (e: any) =>
                e.value === valueDefault?.publishing_date && e.type === valueDefault?.publishing_schedule
        )[0];


        const vTitleTemplate = {
            label: optionTemplateTitle?.name,
            value: String(optionTemplateTitle?.id),
        };
        const vBodyTemplate = {
            label: optionTemplateBody?.name,
            value: String(optionTemplateBody?.id),
        };
        const vTags = valueDefault?.tag_related_news ? valueDefault?.tag_related_news
            ?.split(",")
            ?.map((item: string) => ({ id: item, text: item })) : [];
        const vMedias = (valueDefault?.partners || [])?.map((item: IMedia) => ({
            value: String(item?.partner_id),
            label: item?.name,
        }));

        const vSections = (valueDefault?.partner_codes || [])?.reduce((arr: any = [], item: any) => {
            arr[Number(item?.partner_id)] = ({
                id: item?.partner_id,
                section_code: {
                    label: item?.section_title,
                    value: item?.partner_code_id,
                }
            })
            return arr;
        }, []);

        setTimeout(() => {
            formik.setFieldValue("name", vName);
            formik.setFieldValue("category_type", vCategoryType);
            formik.setFieldValue('classification', vClassification);
            formik.setFieldValue("category", vCategory);
            formik.setFieldValue("schedule", vSchedule);
            formik.setFieldValue("time", vTime);
            formik.setFieldValue("date", vDate);
            formik.setFieldValue("publishing_schedule", vPublishingSchedule);
            formik.setFieldValue("publishing_time", vPublishingTime);
            formik.setFieldValue("publishing_date", vPublishingDate);
            formik.setFieldValue("title_template_id", vTitleTemplate);
            formik.setFieldValue("body_template_id", vBodyTemplate);
            formik.setFieldValue("is_random_chart", vRandomInfographic);
        }, 300);
        setTimeout(() => {
            formik.setFieldValue("tags", vTags);
            formik.setFieldValue("medias", vMedias);
            formik.setFieldValue("sections", vSections)
        }, 400);
    };

    const handleCallAllOption = async (idItem: string) => {
        try {
            if (!idItem) {
                return
            }
            setIsLoadingDetail((_prev) => true);
            const [resDetail]: any = await Promise.all([idItem ? getDetailCampaign(idItem) : {},]);
            if (resDetail?.data) {
                handleSetValueForm(resDetail?.data);
                setIsLoadingDetail((_prev) => false);
            }
        } catch (error: any) {
            setIsLoadingDetail((_prev) => false);
            return error;
        }
    };

    useEffect(() => {
        handleCallAllOption(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        document.title = `${t("Campaign Create")} - ${t("Campaign")} | NewsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        formik.setFieldValue(
            "schedule",
            SCHEDULE_OPTIONS_LANG?.find(
                (e: any) => e.value === initialValueSchedule?.value
            )
        );
        formik.setFieldValue(
            "date",
            DATE_OPTIONS_LANG?.find((e: any) => e.value === initialValueDate?.value)
        );
        formik.setFieldValue(
            "publishing_schedule",
            SCHEDULE_OPTIONS_LANG?.find(
                (e: any) => e.value === initialValuePublishingSchedule?.value
            )
        );
        formik.setFieldValue(
            "publishing_date",
            DATE_OPTIONS_LANG?.find((e: any) => e.value === initialValuePublishingDate?.value)
        );

        formik.setFieldValue(
            "classification",
            listClassifications?.find(
                (e: any) => e.value === initialValueClassification?.value
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            {isLoadingDetail && <div style={{
                position: 'absolute',
                zIndex: 3,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgb(164 164 164 / 36%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Spinner size="sm" color="primary"></Spinner>
            </div>}
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <Card id="leadsList">
                            <CardBody className="pt-3">
                                <Row className={`mt-1 g-5 ${isModal ? 'mb-0' : 'mb-5'}`}>
                                    {!isModal && <Col sm={0} md={0} xl={1} lg={1} className="m-0"></Col>}
                                    <Col sm={12} md={7} xl={isModal ? 6 : 6} lg={isModal ? 7 : 7} className={isModal ? 'mt-2' : ''}>
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-topbar-search-bg)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            {!LIST_TYPE_CATEGORY_NOT_SCHEDULE?.includes(typeCampaignForm) && (
                                                <>
                                                    <Col
                                                        sm={12}
                                                        lg={12}
                                                        className="d-flex justify-content-between align-items-end"
                                                    >
                                                        <div style={{ width: "100%" }}>
                                                            <label className="form-label">
                                                                {t("Category Type")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <CategoryType
                                                                name="category_type"
                                                                dataList={listCategoriesTypeLang}
                                                                placeholder={`${t("Select Category Type")}...`}
                                                                initialValue={
                                                                    initialValueCategoryType ?? undefined
                                                                }
                                                                onChangeSelect={(event) => {
                                                                    formik.setFieldValue("category_type", event);
                                                                    formik.setFieldValue("category", null);
                                                                    formik.setFieldValue("keywords", null);
                                                                    formik.setFieldValue("classification", null);
                                                                    // formik.setFieldValue('tags', null);
                                                                }}
                                                            />
                                                            {formik.touched.category_type &&
                                                                formik.errors.category_type ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.category_type}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col xxl={12}>
                                                        <div>
                                                            <label className="form-label required">
                                                                {t("Category")}
                                                                {OPTIONS_MUST_HAVE_CATEGORY_AND_KEYWORD?.includes(initialValueCategoryType?.value) && <span className="text-danger"> *</span>}
                                                            </label>
                                                            <DropdownCategoryKeyword
                                                                name="category"
                                                                keyValue="id"
                                                                categoryType={initialValueCategoryType?.value}
                                                                dataListNaver={listCategoriesNaver}
                                                                dataListStock={listCategoriesStock}
                                                                dataListCar={listCategoriesCar}
                                                                dataListCoin={listCategoriesCoin}
                                                                dataListTrendIndex={listCategoriesTrendIndex}
                                                                dataListRankify={listCategoriesRankify}
                                                                initialValue={initialValueCategory ?? undefined}
                                                                disabled={!OPTIONS_MUST_HAVE_CATEGORY_AND_KEYWORD?.includes(initialValueCategoryType?.value)}
                                                                onChangeCategory={(event) => {
                                                                    formik.setFieldValue("category", event);
                                                                    // formik.setFieldValue('tags', null);
                                                                }}
                                                            />
                                                            {formik.touched.category &&
                                                                formik.errors.category ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.category}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    {!(id && !isCopy) && (<Col xxl={12}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Classification")}{" "}
                                                            </label>
                                                            <DropdownOption
                                                                name="classification"
                                                                dataList={listClassifications?.filter((op: any) => op?.type === (initialValueCategoryType as any)?.value) || []}
                                                                placeholder={`${t("Select Classification")}...`}
                                                                className="search-filter-classification-type"
                                                                classNamePrefix="name-prefix"
                                                                initialValue={initialValueClassification ?? undefined}
                                                                onChangeSelect={(e: any) => formik.setFieldValue("classification", e)}
                                                                isHasOptionAll={true}
                                                                optionAll={{ label: t('----- None Classification -----'), value: '' }}
                                                            />
                                                            {formik.touched.classification && formik.errors.classification ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.classification}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    )}
                                                    <Col xxl={12}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Keywords")}
                                                                {OPTIONS_MUST_HAVE_CATEGORY_AND_KEYWORD?.includes(initialValueCategoryType?.value) && <span className="text-danger"> *</span>}
                                                            </label>
                                                            <SearchFilterKeyword
                                                                disabled={true}
                                                                name="keywords"
                                                                isGetListOptionDefault={false}
                                                                initialValue={initialValueKeywords || []}
                                                                categoryType={
                                                                    initialValueCategoryType?.value || "default"
                                                                }
                                                                filterByCategory={String(
                                                                    initialValueCategory?.value || ""
                                                                )}
                                                                onChangeSelect={(event) => {
                                                                    formik.setFieldValue("keywords", event);
                                                                    handleSetValueHashtag &&
                                                                        handleSetValueHashtag(event);
                                                                }}
                                                                maxKeyword={20}
                                                            />
                                                            {formik.touched.keywords &&
                                                                formik.errors.keywords ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.keywords}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </>
                                            )}



                                            <Col
                                                sm={12}
                                                lg={12}
                                                className="d-flex justify-content-between align-items-end"
                                            >
                                                <div style={{ width: "100%" }}>
                                                    <label className="form-label">
                                                        {t("Title Template")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <SearchTemplate
                                                        name="title_template_id"
                                                        templates={listTemplate}
                                                        type="title"
                                                        filterByCategoryType={templateCategoryDefaultValue || initialValueCategoryType?.value}
                                                        filterBySchedule={id ? 'now' : initialValueSchedule?.value}
                                                        placeholder={`${t("Select Title Template")}...`}
                                                        initialValue={
                                                            initialValueTitleTemplate ?? undefined
                                                        }
                                                        onChangeSelect={(event) => {
                                                            formik.setFieldValue(
                                                                "title_template_id",
                                                                event
                                                            );
                                                        }}
                                                    />
                                                    {formik.touched.title_template_id &&
                                                        formik.errors.title_template_id ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.title_template_id}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col
                                                sm={12}
                                                lg={12}
                                                className="d-flex justify-content-between align-items-end"
                                            >
                                                <div style={{ width: "100%" }}>
                                                    <label className="form-label">
                                                        {t("Content Template")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <SearchTemplate
                                                        name="body_template_id"
                                                        templates={listTemplate}
                                                        type="body"
                                                        filterByCategoryType={templateCategoryDefaultValue || initialValueCategoryType?.value}
                                                        filterBySchedule={id ? 'now' : initialValueSchedule?.value}
                                                        placeholder={`${t(
                                                            "Select Content Template"
                                                        )}...`}
                                                        initialValue={
                                                            initialValueBodyTemplate ?? undefined
                                                        }
                                                        onChangeSelect={(event) => {
                                                            formik.setFieldValue(
                                                                "body_template_id",
                                                                event
                                                            );
                                                        }}
                                                    />
                                                    {formik.touched.body_template_id &&
                                                        formik.errors.body_template_id ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.body_template_id}
                                                        </div>
                                                    ) : null}
                                                    <div className=" w-100 form-check mt-2">
                                                        <Input
                                                            className="form-check-input"
                                                            name={`is_random_chart`}
                                                            checked={!!initialValueRandomInfographic}
                                                            type="checkbox"
                                                            id={`is_random_chart`}
                                                            onChange={(event: any) => formik.setFieldValue(`is_random_chart`, Boolean(event?.target.checked))}
                                                        />
                                                        <Label className="form-check-label text-muted" style={{ fontSize: '12px' }} htmlFor={`is_random_chart`}>{t('Randomly apply infographic charts')}</Label>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Hashtags")}
                                                    </label>
                                                    <Hashtag
                                                        name="tags"
                                                        placeholder={`${t("Hashtags")}...`}
                                                        suggestions={[]}
                                                        initialValue={initialValueTags || []}
                                                        onChangeTag={(event) => {
                                                            formik.setFieldValue("tags", event);
                                                        }}
                                                        idSelected="hashtags-CampaignFormMulti"
                                                        maxHashtags={20}
                                                        typeShowMore={TYPE_SHOW_MORE_HASHTAG.SCROLL}
                                                        maxHeight="150px"
                                                    />
                                                    {formik.touched.tags && formik.errors.tags ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.tags}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={5} xl={isModal ? 6 : 4} lg={isModal ? 5 : 3} className={isModal ? 'mt-2' : ''}>
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-topbar-search-bg)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            {!LIST_TYPE_CATEGORY_NOT_SCHEDULE?.includes(typeCampaignForm) && (
                                                <>
                                                    <Col sm={12} md={4} lg={4}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Creating Schedule")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Select
                                                                // isDisabled
                                                                options={SCHEDULE_OPTIONS_LANG}
                                                                value={initialValueSchedule}
                                                                name="schedule"
                                                                id="idSchedule"
                                                                autocomplete={false}
                                                                placeholder={`${t("Schedule")}...`}
                                                                className="select-schedule"
                                                                classNamePrefix="name-prefix"
                                                                onChange={(event: any) => {
                                                                    formik.setFieldValue("schedule", event);
                                                                    formik.setFieldValue('publishing_schedule', event);
                                                                    if (
                                                                        OPTIONS_MUST_HAVE_DATE?.includes(
                                                                            event?.value
                                                                        )
                                                                    ) {
                                                                        const val =
                                                                            DATE_OPTIONS_LANG?.filter(
                                                                                (item) => item?.type === event?.value
                                                                            )[0] || null;
                                                                        formik.setFieldValue("date", val);
                                                                        formik.setFieldValue("publishing_date", val);
                                                                    }
                                                                    if (OPTIONS_MUST_HAVE_TIME.includes(
                                                                        event?.value
                                                                    )) {
                                                                        formik.setFieldValue("time", "09:00");
                                                                        formik.setFieldValue("publishing_time", "09:00");
                                                                    }
                                                                }}
                                                            ></Select>
                                                            {formik.touched.schedule &&
                                                                formik.errors.schedule ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik?.errors?.schedule}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} md={4} lg={4}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Creating Date")}{" "}
                                                                {OPTIONS_MUST_HAVE_DATE?.includes(
                                                                    initialValueSchedule?.value
                                                                ) && <span className="text-danger"> *</span>}
                                                            </label>
                                                            <Select
                                                                options={
                                                                    DATE_OPTIONS_LANG?.filter(
                                                                        (item) =>
                                                                            item?.type === initialValueSchedule?.value
                                                                    ) || []
                                                                }
                                                                value={
                                                                    !OPTIONS_MUST_HAVE_DATE?.includes(
                                                                        initialValueSchedule?.value
                                                                    )
                                                                        ? null
                                                                        : initialValueDate
                                                                }
                                                                name="date"
                                                                id="idDate"
                                                                autocomplete={false}
                                                                isDisabled={
                                                                    !OPTIONS_MUST_HAVE_DATE?.includes(
                                                                        initialValueSchedule?.value
                                                                    )
                                                                }
                                                                placeholder={`${t("Date")}...`}
                                                                className="select-schedule"
                                                                classNamePrefix="name-prefix"
                                                                onChange={(event: any) => {
                                                                    formik.setFieldValue("date", event);
                                                                    formik.setFieldValue("publishing_date", event);
                                                                }

                                                                }
                                                            ></Select>
                                                            {formik.touched.date && formik.errors.date ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik?.errors?.date}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} md={4} lg={4}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Creating Time")}{" "}
                                                                {initialValueSchedule?.value !== "now" && (
                                                                    <span className="text-danger"> *</span>
                                                                )}
                                                            </label>
                                                            <div className="date-picker-wrapper-custom">
                                                                <DatePicker
                                                                    className="form-control search"
                                                                    placeholderText={`${t("Time")}...`}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    disabled={
                                                                        initialValueSchedule?.value === "now"
                                                                    }
                                                                    timeIntervals={5}
                                                                    autoComplete="off"
                                                                    isClearable={true}
                                                                    timeCaption={t("Time")}
                                                                    timeFormat="HH:mm"
                                                                    value={
                                                                        initialValueSchedule?.value === "now"
                                                                            ? ""
                                                                            : formik.values.time
                                                                    }
                                                                    locale={i18n?.language === "ko" ? "ko" : "en"}
                                                                    name="time"
                                                                    onChange={(value: Date | null) => {
                                                                        formik.setFieldValue("time", value ? moment(new Date(value)).format("HH:mm") : "");
                                                                        formik.setFieldValue("publishing_time", value ? moment(new Date(value)).format("HH:mm") : "")
                                                                    }
                                                                    }
                                                                />
                                                            </div>
                                                            {formik.touched.time && formik.errors.time ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.time}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    {/* Begin::Publishing */}
                                                    <Col sm={12} md={4} lg={4}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Publishing Schedule")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Select
                                                                // isDisabled
                                                                options={SCHEDULE_OPTIONS_LANG}
                                                                value={initialValuePublishingSchedule}
                                                                name="publishing_schedule"
                                                                id="idPublishingSchedule"
                                                                autocomplete={false}
                                                                placeholder={`${t("Schedule")}...`}
                                                                className="select-schedule"
                                                                classNamePrefix="name-prefix"
                                                                onChange={(event: any) => {
                                                                    formik.setFieldValue("publishing_schedule", event);
                                                                    if (
                                                                        OPTIONS_MUST_HAVE_DATE?.includes(
                                                                            event?.value
                                                                        )
                                                                    ) {
                                                                        const val =
                                                                            DATE_OPTIONS_LANG?.filter(
                                                                                (item) => item?.type === event?.value
                                                                            )[0] || null;
                                                                        formik.setFieldValue("publishing_date", val);
                                                                    }
                                                                    if (OPTIONS_MUST_HAVE_TIME.includes(
                                                                        event?.value
                                                                    )) {
                                                                        formik.setFieldValue("publishing_time", "09:00");
                                                                    }
                                                                }}
                                                            ></Select>
                                                            {formik.touched.publishing_schedule &&
                                                                formik.errors.publishing_schedule ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik?.errors?.publishing_schedule}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} md={4} lg={4}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Publishing Date")}{" "}
                                                                {OPTIONS_MUST_HAVE_DATE?.includes(
                                                                    initialValuePublishingSchedule?.value
                                                                ) && <span className="text-danger"> *</span>}
                                                            </label>
                                                            <Select
                                                                options={
                                                                    DATE_OPTIONS_LANG?.filter(
                                                                        (item) =>
                                                                            item?.type === initialValuePublishingSchedule?.value
                                                                    ) || []
                                                                }
                                                                value={
                                                                    !OPTIONS_MUST_HAVE_DATE?.includes(
                                                                        initialValuePublishingSchedule?.value
                                                                    )
                                                                        ? null
                                                                        : initialValuePublishingDate
                                                                }
                                                                name="publishing_date"
                                                                id="idPublishingDate"
                                                                autocomplete={false}
                                                                isDisabled={
                                                                    !OPTIONS_MUST_HAVE_DATE?.includes(
                                                                        initialValuePublishingSchedule?.value
                                                                    )
                                                                }
                                                                placeholder={`${t("Date")}...`}
                                                                className="select-schedule"
                                                                classNamePrefix="name-prefix"
                                                                onChange={(event: any) =>
                                                                    formik.setFieldValue("publishing_date", event)
                                                                }
                                                            ></Select>
                                                            {formik.touched.publishing_date && formik.errors.publishing_date ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik?.errors?.publishing_date}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} md={4} lg={4}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Publishing Time")}{" "}
                                                                {initialValuePublishingSchedule?.value !== "now" && (
                                                                    <span className="text-danger"> *</span>
                                                                )}
                                                            </label>
                                                            <div className="date-picker-wrapper-custom">
                                                                <DatePicker
                                                                    className="form-control search"
                                                                    placeholderText={`${t("Time")}...`}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    disabled={
                                                                        initialValuePublishingSchedule?.value === "now"
                                                                    }
                                                                    timeIntervals={5}
                                                                    autoComplete="off"
                                                                    isClearable={true}
                                                                    timeCaption={t("Time")}
                                                                    timeFormat="HH:mm"
                                                                    value={
                                                                        initialValuePublishingSchedule?.value === "now"
                                                                            ? ""
                                                                            : formik.values.publishing_time
                                                                    }
                                                                    locale={i18n?.language === "ko" ? "ko" : "en"}
                                                                    name="publishing_time"
                                                                    onChange={(value: Date | null) =>
                                                                        formik.setFieldValue(
                                                                            "publishing_time",
                                                                            value
                                                                                ? moment(new Date(value)).format(
                                                                                    "HH:mm"
                                                                                )
                                                                                : ""
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            {formik.touched.publishing_time && formik.errors.publishing_time ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.publishing_time}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </>
                                            )}
                                            {/* End::Publishing */}
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Campaign Name")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        autocomplete={false}
                                                        value={String(initialValueNameCampaign ?? "")}
                                                        placeholder={`${t("Campaign Name")}...`}
                                                        onChange={(event: any) =>
                                                            formik.setFieldValue(
                                                                "name",
                                                                event?.target?.value || ""
                                                            )
                                                        }
                                                    />
                                                    {formik.touched.name && formik.errors.name ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col xxl={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Media")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <SearchFilterMedia
                                                        name="medias"
                                                        dataList={listMedia}
                                                        isMulti={false}
                                                        initialValue={initialValueMedias || []}
                                                        onChangeSelect={(event: any) => {
                                                            formik.setFieldValue("medias", [event]);
                                                            const valSections = listMedia?.filter((m) => (String(m?.id) === String(event?.value)))[0]?.partner_codes || [];
                                                            formik.setFieldValue(`sections[${event?.value}]section_code`, valSections[0] ? ({
                                                                label: valSections[0]?.section_title,
                                                                value: String(valSections[0]?.id),
                                                            }) : null)
                                                        }}
                                                    />
                                                    {formik.touched.medias && formik.errors.medias ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.medias}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            {/* Begin::List Section */}
                                            {/* formik?.values?.sections ||  */}
                                            <div>
                                                {(initialValueMedias || [])?.map((item: any, index: number) => (
                                                    <div className="p-4 position-relative mt-4" key={item?.value || index} style={{ border: '1px dashed var(--vz-primary)', backgroundColor: 'var(--vz-light)', borderRadius: '5px' }}>
                                                        {/* Begin::Item Template For Media */}
                                                        <div className="position-absolute top-0"
                                                            style={{
                                                                transform: 'translateY(-50%)',
                                                                padding: '5px 10px',
                                                                backgroundColor: 'var(--vz-primary-bg-subtle)',
                                                                color: 'var(--vz-primary)',
                                                                borderRadius: '5px',
                                                                border: '1px dashed var(--vz-primary)',
                                                                fontWeight: 500
                                                            }}
                                                        >
                                                            {item?.label}
                                                        </div>
                                                        <Row>
                                                            <Col md={12}>
                                                                <label className="form-label">{t('Section Title')}</label>
                                                                <DropdownSectionCodeMediaType
                                                                    name={`sections[${item?.value}]section_code`}
                                                                    dataList={listMedia?.filter((m) => (String(m?.id) === String(item?.value)))[0]?.partner_codes || []}
                                                                    initialValue={(formik?.values?.sections?.[item?.value] as any)?.section_code}
                                                                    onChangeSelect={(event: any) => {
                                                                        formik.setFieldValue(`sections[${item?.value}]`, { id: item?.value, section_code: event })
                                                                    }}
                                                                />
                                                                {(formik.touched?.sections?.[item?.value] as any)?.section_code && (formik.errors?.sections?.[index] as any)?.section_code ? (
                                                                    <div className="text-danger mt-2">{(formik.errors?.sections?.[index] as any)?.section_code}</div>
                                                                ) : null}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))}
                                            </div>
                                            {/* End::List Section */}

                                            <div className="col-lg-12">
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-secondary fs-14"
                                                        color="light"
                                                        type="button"
                                                        onClick={onPreviewClick}
                                                        disabled={
                                                            isPreviewLoading ||
                                                            isLoading ||
                                                            isDisabledPreview
                                                        }
                                                    >
                                                        {isPreviewLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) : (
                                                            <i className="ri-eye-line align-bottom me-1"></i>
                                                        )}
                                                        {t("Button Preview")}
                                                    </button>
                                                    <button
                                                        className="btn btn-success fs-14"
                                                        color="light"
                                                        type="button"
                                                        onClick={onSaveClick}
                                                        disabled={isLoading || isPreviewLoading}
                                                    >
                                                        {isLoading &&
                                                            initialValueActionType ===
                                                            ACTION_TYPE_CAMPAIGN.SAVE ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) : (
                                                            <i className="ri-login-circle-line align-bottom me-1"></i>
                                                        )}
                                                        {t("Button Save")}
                                                    </button>
                                                    <button
                                                        className="btn btn-primary fs-14"
                                                        color="success"
                                                        type="button"
                                                        onClick={onPostClick}
                                                        disabled={isLoading || isPreviewLoading}
                                                    >
                                                        {isLoading &&
                                                            initialValueActionType ===
                                                            ACTION_TYPE_CAMPAIGN.POST ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) : (
                                                            <i className="ri-send-plane-line align-bottom me-1"></i>
                                                        )}
                                                        {t("Button Post")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    {!isModal && <Col sm={0} md={0} xl={1} lg={1} className="m-0"></Col>}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </form>
            <ToastContainer closeButton={false} limit={1} />
            <Modal
                isOpen={isOpenPreview}
                centered={true}
                size="xl"
                toggle={onClosePreviewClick}
                keyboard={true}
                scrollable={true}
            >
                <ModalHeader toggle={isPreviewLoading ? () => { } : onClosePreviewClick}>
                    {t("Template Preview")}
                </ModalHeader>
                <ModalBody className="">
                    <div className="row g-4">
                        <Col md={12}>
                            <div>
                                <div className="row g-3">
                                    <Col xxl={12} className="ribbon-box right">
                                        {!!previewTemplate?.memo?.errors?.length && (
                                            <div className="ribbon ribbon-danger round-shape top-0" style={{
                                                zIndex: 9,
                                                background: 'rgb(255 255 255 / 67%)',
                                                color: '#f06548',
                                                borderRadius: 0,
                                                maxHeight: '30dvh',
                                                overflowY: 'auto',
                                            }}>
                                                <span style={{ fontSize: '12px', lineHeight: '12px', fontWeight: 'normal' }}>{(previewTemplate?.memo?.errors || [])?.map((mess: any) => (
                                                    <>{`${mess}`}<br /> </>
                                                ))}
                                                </span>
                                            </div>
                                        )}
                                        <div className="bg-primary-subtle position-relative ">
                                            <div className="p-5 card-body">
                                                <div className="text-center">
                                                    <h3 className="fw-semibold">
                                                        {previewTemplate?.title || ""}
                                                    </h3>
                                                </div>

                                            </div>

                                            <div className="shape">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    version="1.1"
                                                    width="1440"
                                                    height="60"
                                                    preserveAspectRatio="none"
                                                    viewBox="0 0 1440 60"
                                                >
                                                    <g mask='url("#SvgjsMask1001")' fill="none">
                                                        <path
                                                            d="M 0,4 C 144,13 432,48 720,49 C 1008,50 1296,17 1440,9L1440 60L0 60z"
                                                            style={{ fill: "var(--vz-secondary-bg)" }}
                                                        ></path>
                                                    </g>
                                                    <defs>
                                                        <mask id="SvgjsMask1001">
                                                            <rect
                                                                width="1440"
                                                                height="60"
                                                                fill="#ffffff"
                                                            ></rect>
                                                        </mask>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={12}>

                                        <div
                                            className="clear-css-p-in-box"
                                            dangerouslySetInnerHTML={{
                                                __html: previewTemplate?.content || "",
                                            }}
                                        ></div>
                                    </Col>
                                    <div className="col-lg-12">
                                        <div className="hstack gap-2 justify-content-end">
                                            <button
                                                className="btn btn-light fs-14"
                                                type="button"
                                                color="light"
                                                onClick={onClosePreviewClick}
                                            >
                                                <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                                                {t("Button Close")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>
                </ModalBody>
            </Modal>
            <ModalConfirm
                textButtonConfirm={t('Button Post')}
                classButtonConfirm='btn-primary'
                classIconButtonConfirm='ri-send-plane-line'
                header={isCopy ? t('Campaign Create') : t('Button Update Campaign')}
                title={t('Are you sure you want to post this?')}
                content={isCopy ? t('After confirming the post action, the data will be created. Do you want to proceed with the confirming action.') : t('After confirming the post action, the data will be updated. Do you want to proceed with the confirming action.')}
                isShowIcon={false}
                isOpen={isOpenConfirmCreate}
                onClose={onCloseConfirmCreateClick}
                onConfirm={handleCreateCampaign}
            />
        </React.Fragment >
    );
};

export default CampaignFormEdit;
