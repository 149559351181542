import { IAdsAccount, IMedia, IMediaCode, IType } from "api/types/_media";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import * as Yup from "yup";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import {
  deleteMedia,
  getAdsAccountNormal,
  getAllTypes,
  getConfigMediaById,
  getMedias as onGetMedias,
  postMedia,
  putAccountMedia,
  putMedia,
  refreshKey,
  updateConfigMedia,
} from "../../../store/thunks";

import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownOption from "components/Common/DropdownOption";
import EditorCustom from "components/Common/EditorCustom";
import FilterTypes from "components/Common/FilterTypes";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TooltipCustom from "components/Common/TooltipCustom";
import Types from "components/Common/Types";
import { useRole } from "components/Hooks/UserHooks";
import { TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS } from "helpers/constans";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import CountUp from "react-countup";
import History from "../AdsAccount/History";
import RevenueRate from "../AdsAccount/RevenueRate";
interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "id";

registerLocale("en", en);
registerLocale("ko", ko);

const SECTION_DEFAULT: IMediaCode = {
  id: String(+new Date()),
  section_title: "",
  section_code: "",
  reporter: "",
  reporter_id: "",
};

const MediaList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS_LANG =
    TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    }));

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    // order_by: withDefault(StringParam, `DESC`),
    // date_version: withDefault(StringParam, ''),
    name: withDefault(StringParam, ""),
    domain_ids: withDefault(StringParam, ""),
    type: withDefault(StringParam, ""),
  });

  const refContentNews = useRef<any>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [isLoadingConfig, setIsLoadingConfig] = useState<boolean>(false);
  const [isLoadingUpdateConfig, setIsLoadingUpdateConfig] = useState<any>(null);
  const [configDetail, setConfigDetail] = useState<any>({});
  const [isOpenUpdateConfigMedia, setIsOpenUpdateConfigMedia] =
    useState<boolean>(false);
  const [isOpenConfirmRefreshKey, setIsConfirmRefreshKey] =
    useState<boolean>(false);
  const [isConfirmRefreshKeyLoading, setIsConfirmRefreshKeyLoading] =
    useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] = useState<
    (IMedia & { is_update_ads_account?: boolean }) | null
  >(null);
  const [isOpenUpdateAccountMedia, setIsOpenUpdateAccountMedia] =
    useState<boolean>(false);

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.name || "");
  const [formatSearch, setFormatSearch] = useState<Option | null>(
    TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS_LANG?.filter(
      (item) => item?.value === query?.type
    )[0] || { label: t("All Status"), value: "" }
  );
  const [typesSearch, setTypesSearch] = useState<Option[]>([]);

  const [passwordShow, setPasswordShow] = useState<boolean>(false);

  const [listType, setListType] = useState<IType[]>([]);

  const [adsAccount, setAdsAccount] = useState<IAdsAccount[]>([]);
  const [valueRate, setValueRate] = useState<any>({});
  const [idAdsAccountLoading, setIdAdsAccountLoading] = useState<number | null>(
    null
  );

  const [targetField, setTargetField] = useState<any>(null);

  const [accountIdShowHistory, setAccountIdShowHistory] = useState<any>(null);

  const [accountShowRevenueRate, setAccountShowRevenueRate] =
    useState<any>(null);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Media;

  const MediaProperties = createSelector(selectLayoutState, (state) => ({
    medias: state.medias,
    isMediaSuccess: state.isMediaSuccess,
    isMediaLoading: state.isMediaLoading,
    error: state.error,
  }));

  // Inside your component
  const { medias, error, isMediaLoading } = useSelector(MediaProperties);

  useEffect(() => {
    dispatch(onGetMedias(query));
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      name: keywordSearch || undefined,
      type: formatSearch?.value || undefined,
      domain_ids:
        (typesSearch || [])?.map((item) => item?.value).join(",") || "",
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
    });
  };

  const resetData = () => {
    setQuery(
      {
        name: undefined,
        domain_ids: undefined,
        sort_by: undefined,
        time_request: +new Date(),
      },
      "push"
    );
    setKeywordSearch((_prev) => "");
    setFormatSearch((_prev) => null);
    setTypesSearch((_prev) => []);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Id"),
        accessor: "id",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t("Media Name"),
        accessor: "name",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="cursor-pointer" onClick={() => handleEdit(item)}>
                {cell?.value}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Description"),
        accessor: "description",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="cursor-pointer" onClick={() => handleEdit(item)}>
                {cell?.value}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Website"),
        accessor: "website",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <Link
                className="text-normal"
                to={
                  String(cell?.value).includes("https://") ||
                    String(cell?.value).includes("http://")
                    ? cell?.value
                    : `https://${cell?.value}`
                }
                target="_blank"
              >
                {cell?.value}
              </Link>
            </>
          );
        },
      },
      {
        Header: t("Email"),
        accessor: "email",
        filterable: true,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => {
          return (
            <>
              <Link className="text-normal" to={`mailto: ${cell?.value || ""}`}>
                {cell?.value}
              </Link>
            </>
          );
        },
      },
      {
        Header: t("Types Media"),
        accessor: "types",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const types = value?.domains || [];
          let tagName: string = "";
          for (let item of types) {
            tagName += `${item ? `<span class='tag-style'>${item?.name} </span>` : ""
              }`;
          }
          return <div dangerouslySetInnerHTML={{ __html: tagName }} />;
        },
      },
      {
        Header: t("Transmission format"),
        accessor: "type",
        filterable: true,
        sortable: false,
        thClass: "text-center",
        description: t("Media transmission format classification"),
        Cell: (cell: any) => (
          <>
            {cell?.value ? (
              <div className="text-center" style={{ minWidth: "170px" }}>
                <div className="tag-style text-center">
                  {t(`Format_Type_[${cell?.value}]_For_Media`)}
                </div>{" "}
              </div>
            ) : (
              ""
            )}
          </>
        ),
      },
      {
        Header: t("Total Section"),
        accessor: "partner_codes",
        filterable: true,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => (
          <>
            <div className="text-center" style={{ minWidth: "100px" }}>
              {cell?.value ? <div>{(cell?.value || [])?.length}</div> : ""}
            </div>
          </>
        ),
      },
      {
        Header: t("Created at"),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || "").split(" ");
          return (
            <div className="text-end">
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Updated at"),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || "").split(" ");
          return (
            <div className="text-end">
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Button Action"),
        thClass: "text-center",
        thWidth: 150,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul
              className="list-inline d-flex flex-wrap hstack gap-2 mb-0"
              style={{ width: "120px" }}
            >
              <TooltipCustom
                title={t("Button Detail Media")}
                id={`detail-md-${item?.id}`}
              >
                <li className="list-inline-item" id={`detail-md-${item?.id}`}>
                  <Link
                    className="btn btn-sm btn-soft-secondary edit-item-btn"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleEdit(item, true);
                    }}
                  >
                    <i className="ri-eye-fill align-bottom "></i>
                  </Link>
                </li>
              </TooltipCustom>
              {isHavePermissionRole(
                ROLES_FOR_APP.MEDIA_CREATE_ACCOUNT_CONSOLE,
                userPermissions
              ) && (
                  <TooltipCustom
                    title={t("Button Update Account Media")}
                    id={`update-md-${item?.id}`}
                  >
                    <li className="list-inline-item" id={`update-md-${item?.id}`}>
                      <Link
                        className="btn btn-sm btn-soft-secondary edit-item-btn"
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleShowUpdateAccount(item);
                        }}
                      >
                        <i className="ri-user-3-fill align-bottom "></i>
                      </Link>
                    </li>
                  </TooltipCustom>
                )}
              {isHavePermissionRole(
                ROLES_FOR_APP.MEDIA_UPDATE,
                userPermissions
              ) && (
                  <TooltipCustom
                    title={t("Button Update Media")}
                    id={`update-md-${item?.id}`}
                  >
                    <li className="list-inline-item" id={`update-md-${item?.id}`}>
                      <Link
                        className="btn btn-sm btn-soft-secondary edit-item-btn"
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleEdit(item);
                        }}
                      >
                        <i className="ri-pencil-fill align-bottom "></i>
                      </Link>
                    </li>
                  </TooltipCustom>
                )}
              {isHavePermissionRole(
                ROLES_FOR_APP.MEDIA_UPDATE,
                userPermissions
              ) && (
                  <TooltipCustom
                    title={t("Ads Account")}
                    id={`update-ads-md-${item?.id}`}
                  >
                    <li className="list-inline-item" id={`update-md-${item?.id}`}>
                      <Link
                        className={`btn btn-sm btn-soft-secondary edit-item-btn ${idAdsAccountLoading === item?.id ? "disabled" : ""
                          }`}
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleShowAdsAccountEdit(item);
                        }}
                      >
                        {idAdsAccountLoading === item?.id ? (
                          <Spinner
                            size="sm"
                            style={{
                              width: "13px",
                              height: "13px",
                              verticalAlign: "middle",
                            }}
                          ></Spinner>
                        ) : (
                          <i className="ri-shield-star-fill align-bottom "></i>
                        )}
                      </Link>
                    </li>
                  </TooltipCustom>
                )}
              {isHavePermissionRole(
                ROLES_FOR_APP.MEDIA_UPDATE,
                userPermissions
              ) && (
                  <TooltipCustom
                    title={t("Button Config Media")}
                    id={`delete-md-${item?.id}`}
                  >
                    <li className="list-inline-item" id={`delete-md-${item?.id}`}>
                      <Link
                        className="btn btn-sm btn-soft-secondary edit-item-btn"
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleShowConfigMedia(item);
                        }}
                      >
                        <i className="ri-settings-3-fill align-bottom"></i>
                      </Link>
                    </li>
                  </TooltipCustom>
                )}
              {isHavePermissionRole(
                ROLES_FOR_APP.MEDIA_DELETE,
                userPermissions
              ) && (
                  <TooltipCustom
                    title={t("Button Delete Media")}
                    id={`delete-md-${item?.id}`}
                  >
                    <li className="list-inline-item" id={`delete-md-${item?.id}`}>
                      <Link
                        className="btn btn-sm btn-soft-secondary edit-item-btn"
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleConfirmDelete(item);
                        }}
                      >
                        <i className="ri-delete-bin-3-fill align-bottom"></i>
                      </Link>
                    </li>
                  </TooltipCustom>
                )}
            </ul>
          );
        },
      },
    ],
    [i18n?.language, userPermissions, idAdsAccountLoading]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
  }, []);

  // Begin::Delete
  const handleConfirmDelete = (item: IMedia) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!initialValuesDefault?.id) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteMedia(initialValuesDefault?.id);
      if (response?.code === 200) {
        dispatch(onGetMedias(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  };

  const onCloseConfirmRefreshKeyClick = () => {
    setIsConfirmRefreshKey((_prev) => false);
  };
  // End::Delete

  // Begin::Update Ads Account

  const handleShowAdsAccountEdit = async (item: IMedia) => {
    try {
      setIdAdsAccountLoading((_prev: any) => item?.id);
      const response: any = await getAdsAccountNormal({
        page: 1,
        limit: 1000,
        partner_id: item?.id,
      });
      setIdAdsAccountLoading((_prev: any) => null);
      if (response?.data) {
        setAdsAccount((prev) => response?.data?.list || []);
        setValueRate((prev: any) => {
          return response?.data?.list?.reduce(
            (ob: any, el: IAdsAccount) => ({
              ...ob,
              [`cell-${el?.id}`]: {
                partner_revenue_rate: el?.partner_revenue_rate,
                system_revenue_rate: el?.system_revenue_rate,
                effective_from: el?.effective_from
                  ? moment(el?.effective_from || "", "Y-MM-DD").toDate()
                  : "",
                effective_to: el?.effective_to
                  ? moment(el?.effective_to || "", "Y-MM-DD").toDate()
                  : "",
              },
            }),
            {}
          );
        });
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIdAdsAccountLoading((_prev: any) => null);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
    setInitialValuesDefault((_prev) => ({
      ...item,
      is_update_ads_account: true,
    }));
  };

  const onCloseUpdateAdsAccountClick = () => {
    setInitialValuesDefault((_prev) => null);
  };

  // End::Update Ads Account

  // Form

  function onCloseClick() {
    setIsOpen((_prev) => !_prev);
    setTimeout(() => {
      formik.setErrors({});
    }, 300);
  }

  function onCreateClick() {
    setInitialValuesDefault((_prev) => null);
    handleResetForm();
    setIsOpen((_prev) => true);
  }

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        name: values?.name ?? "",
        website: values?.website ?? "",
        news_url_format: values?.news_url_format ?? "",
        description: values?.description ?? "",
        type: values?.format?.value ?? "",
        topic_names: [],
        domains: (values?.types || [])?.map((item: Tag) => item?.id) || [],
        media_codes:
          (values?.sections || [])?.map((item: any) => ({
            section_title: item?.section_title,
            section_code: item?.section_code,
            reporter: item?.reporter,
            reporter_id: item?.reporter_id,
          })) || [],
      };
      const response: any = !!initialValuesDefault?.id
        ? await putMedia(initialValuesDefault?.id, data)
        : await postMedia(data);
      if (response?.data) {
        dispatch(onGetMedias(query));
        setIsLoading((_prev) => false);
        setIsOpen((_prev) => false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleActionRefreshKey = async () => {
    if (!initialValuesDefault?.id) {
      return;
    }
    try {
      setIsConfirmRefreshKeyLoading((_prev) => true);
      const response: any = await refreshKey(initialValuesDefault?.id);
      if (response?.data) {
        dispatch(onGetMedias(query));
        setIsConfirmRefreshKeyLoading((_prev) => false);
        setIsConfirmRefreshKey((_prev) => false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        formik.setFieldValue("api_secret", response?.data?.api_secret);
        formik.setFieldValue("api_key", response?.data?.api_key);
      } else {
        setIsConfirmRefreshKeyLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmRefreshKeyLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const sectionSchema = Yup.object().shape({
    id: Yup.string(),
    section_title: Yup.string().required(`${t("Title is required")}`),
    section_code: Yup.string().required(`${t("Code is required")}`),
    reporter: Yup.string().required(`${t("Reporter is required")}`),
    reporter_id: Yup.string().required(`${t("Reporter ID is required")}`),
  });

  const validationSchema = Yup.object({
    name: Yup.string().required(`${t("Media Name is required")}`),
    website: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        `${t("Enter correct url")}`
      )
      .nullable(),
    news_url_format: Yup.string().nullable(),
    description: Yup.string().nullable(),
    id: Yup.string().nullable(),
    api_secret: Yup.string().nullable(),
    api_key: Yup.string().nullable(),
    format: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .required(`${t("Format is required")}`),
    types: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(`${t("Please provide a label")}`),
          text: Yup.string().required(`${t("Please provide a value")}`),
        })
      )
      .required(`${t("Types is required")}`)
      .min(1, `${t("At least 1 type is required")}`),
    sections: Yup.array()
      .of(sectionSchema)
      .min(1, `${t("At least 1 section is required")}`)
      .required(`${t("At least 1 section is required")}`),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      website: "",
      news_url_format: "",
      id: "",
      api_secret: "",
      api_key: "",
      description: "",
      format: TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS[0],
      types: null,
      sections: [{ ...SECTION_DEFAULT, id: String(+new Date()) }],
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleEdit = (item: IMedia, isPreview: boolean = false) => {
    setPasswordShow((_prev) => false);
    setInitialValuesDefault((_prev) => ({ ...item, isPreview }));
    setIsOpen((_prev) => true);
    const vTags = item?.domains?.map((item: IType) => ({
      id: item?.name,
      text: item?.name,
    }));
    const vFormat = TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS?.filter(
      (op) => op?.value === item?.type
    )[0];
    const vSections = (
      item?.partner_codes?.length
        ? item?.partner_codes
        : [{ ...SECTION_DEFAULT, id: String(+new Date()) }]
    )?.map((item: IMediaCode) => item);
    formik.setFieldValue("name", item?.name);
    formik.setFieldValue("website", item?.website);
    formik.setFieldValue("news_url_format", item?.news_url_format);
    formik.setFieldValue("id", item?.id);
    formik.setFieldValue("api_secret", item?.api_secret);
    formik.setFieldValue("api_key", item?.api_key);
    formik.setFieldValue("description", item?.description);
    formik.setFieldValue("format", vFormat);
    formik.setFieldValue("types", vTags);
    formik.setFieldValue("sections", vSections);
  };

  const handleResetForm = () => {
    formik.setFieldValue("name", "");
    formik.setFieldValue("website", "");
    formik.setFieldValue("id", "");
    formik.setFieldValue("api_secret", "");
    formik.setFieldValue("api_key", "");
    formik.setFieldValue("description", "");
    formik.setFieldValue("format", TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS[0]);
    formik.setFieldValue("types", null);
    formik.setFieldValue("sections", [
      { ...SECTION_DEFAULT, id: String(+new Date()) },
    ]);
    setTimeout(() => {
      formik.setErrors({});
    }, 300);
  };

  function onCloseUpdateAccountClick() {
    setIsOpenUpdateAccountMedia((_prev) => !_prev);
    setTimeout(() => {
      formikAccount.setErrors({});
    }, 300);
  }

  const handleSubmitUpdateAccount = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        email: values?.email ?? "",
        password: values?.password ?? "",
        profile_url: values?.profile_url ?? "",
        contact_name: values?.contact_name ?? "",
        contact_phone: values?.contact_phone ?? "",
        contact_email: values?.contact_email ?? "",
      };
      const response: any = await putAccountMedia(
        initialValuesDefault?.id || "",
        data
      );
      if (response?.data) {
        dispatch(onGetMedias(query));
        setIsLoading((_prev) => false);
        setIsOpenUpdateAccountMedia((_prev) => false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const validationSchemaAccount = Yup.object({
    email: Yup.string()
      .email(`${t("Please enter a valid email address")}`)
      .required(`${t("Email is required")}`),
    password: Yup.string()
      .min(8, `${t("Password must be at least 8 characters")}`)
      .nullable(),
    profile_url: Yup.string().nullable(),
    contact_name: Yup.string().nullable(),
    contact_phone: Yup.string()
      .matches(/^\d+$/, `${t("Phone number should only contain digits")}`)
      .nullable(),
    contact_email: Yup.string()
      .email(`${t("Please enter a valid email address")}`)
      .nullable(),
  });

  const formikAccount = useFormik({
    initialValues: {
      email: "",
      password: "",
      profile_url: "",
      contact_name: "",
      contact_phone: "",
      contact_email: "",
    },
    validationSchema: validationSchemaAccount,
    onSubmit: handleSubmitUpdateAccount,
  });

  const handleShowUpdateAccount = (item: IMedia) => {
    setInitialValuesDefault((_prev) => item);
    setIsOpenUpdateAccountMedia((_prev) => true);
    formikAccount.setFieldValue("email", item?.email || "");
    formikAccount.setFieldValue("password", "");
    formikAccount.setFieldValue("profile_url", item?.profile_url || "");
    formikAccount.setFieldValue("contact_name", item?.contact_name || "");
    formikAccount.setFieldValue("contact_phone", item?.contact_phone || "");
    formikAccount.setFieldValue("contact_email", item?.contact_email || "");
    setTimeout(() => {
      formikAccount.setErrors({});
    }, 300);
  };

  const handleShowConfigMedia = (item: IMedia) => {
    setInitialValuesDefault((_prev) => item);
    setIsOpenUpdateConfigMedia((_prev) => true);
    setTimeout(() => {
      formikAccount.setErrors({});
    }, 300);
  };

  const onCloseConfigClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsOpenUpdateConfigMedia((_prev) => false);
    setConfigDetail(null);
  };

  const handleCallAllOption = async () => {
    try {
      const [resType]: any = await Promise.all([getAllTypes()]);
      setListType((_prev) => resType?.data || []);
      // Begin::set value default for form filter
      const series = String(query?.domain_ids || "").split(",");
      const optionTypes = (resType?.data || [])?.reduce(
        (arr: any, item: any) => {
          return series?.includes(String(item?.id))
            ? arr.concat([{ value: String(item?.id), label: item?.name }])
            : arr;
        },
        []
      );
      setTypesSearch((_prev) => optionTypes || []);
      // End::set value default for form filter
    } catch (error: any) {
      return error;
    }
  };

  const copyTextToClipboard = async (text: string) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }
    toast(`${t("Copied Successfully")}`, CONFIG_OPTION_TOAST_NORMAL);
  };

  //@ts-ignore
  const initialValueTypes = (formik?.values?.types as Tag[]) || [];

  //@ts-ignore
  const suggestionsTypes =
    ((listType || [])?.map((item) => ({
      id: item?.name,
      text: item?.name,
    })) as Tag[]) || [];

  //@ts-ignore
  const initialValueSections = formik?.values?.sections || [];

  const handleRemoveSection = (section: any) => {
    const arr = [...initialValueSections]?.filter(
      (item) => section?.id !== item?.id
    );
    formik.setFieldValue("sections", arr);
  };

  const handleAddSection = () => {
    const arr = [...initialValueSections].concat([
      { ...SECTION_DEFAULT, id: String(+new Date()) },
    ]);
    formik.setFieldValue("sections", arr);
  };

  const getConfigMedia = async (id: any) => {
    if (id) {
      setIsLoadingConfig(true);
      try {
        const res: any = await getConfigMediaById(initialValuesDefault?.id);
        if (res?.code === 200) {
          setConfigDetail(res?.data || {});
        }
        setIsLoadingConfig(false);
      } catch (error) {
        setIsLoadingConfig(false);
      }
    }
  };

  const handleUpdateConfig = async (id: any, key: string, data: any) => {
    setIsLoadingUpdateConfig(key);
    try {
      const res: any = await updateConfigMedia(id, data);
      if (res?.code === 200) {
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        // getConfigMedia(initialValuesDefault?.id);
      }
      if (key !== "news_byline") {
        setTargetField(null);
      }

      setIsLoadingUpdateConfig(null);
    } catch (error: any) {
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      setIsLoadingUpdateConfig(null);
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (!formatSearch?.value) {
      setFormatSearch((prev) => null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    if (isOpenUpdateConfigMedia && initialValuesDefault) {
      getConfigMedia(initialValuesDefault?.id);
    }
    // eslint-disable-next-line
  }, [isOpenUpdateConfigMedia]);

  useEffect(() => {
    document.title = `${t("Media")} - ${t("Media")} | NewsHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Media")} pageTitle={t("Media")} />
          <Row>
            {isHavePermissionRole(
              ROLES_FOR_APP.MEDIA_LIST,
              userPermissions
            ) && (
                <Col lg={12}>
                  <Card id="leadsList">
                    <CardHeader className="border-0">
                      <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-lg-end mb-2">
                        <Card
                          className="card-animate mb-0 me-0 me-md-4 mt-2 bg-primary-subtle text-primary order-1 order-md-0 border-0 d-none d-md-block"
                          style={{ width: "200px" }}
                        >
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                                  {t("Total")}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                <span className="counter-value text-primary">
                                  {isMediaLoading ? (
                                    <Spinner size="sm"></Spinner>
                                  ) : (
                                    <CountUp
                                      start={0}
                                      end={medias?.pagination?.total || 0}
                                      duration={1}
                                    />
                                  )}
                                </span>
                              </h4>
                            </div>
                          </CardBody>
                        </Card>
                        <div className="w-100">
                          <div className="align-items-end">
                            <Row className="mt-0">
                              {isHavePermissionRole(
                                ROLES_FOR_APP.MEDIA_CREATE,
                                userPermissions
                              ) && (
                                  <Col
                                    lg={12}
                                    className="text-end  mb-2 mb-md-0 d-block d-md-none"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-success"
                                      onClick={() => onCreateClick()}
                                    >
                                      <i className="ri-add-fill align-bottom me-1"></i>{" "}
                                      {t("Button Create Media")}
                                    </button>
                                  </Col>
                                )}
                              <Col sm={12} md={4} lg={2} className="mt-2">
                                <LabelWrapper
                                  label={t("Transmission format")}
                                  isShow={!!formatSearch?.value}
                                >
                                  <DropdownOption
                                    name="format"
                                    dataList={
                                      TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS_LANG ||
                                      []
                                    }
                                    placeholder={`${t("Transmission format")}...`}
                                    className="search-filter-category-type"
                                    classNamePrefix="name-prefix"
                                    initialValue={formatSearch || null}
                                    onChangeSelect={(e: any) =>
                                      setFormatSearch(e)
                                    }
                                    isHasOptionAll={true}
                                    optionAll={{
                                      label: t("All Transmission format"),
                                      value: "",
                                    }}
                                  />
                                </LabelWrapper>
                              </Col>
                              <Col sm={12} md={8} lg={4} className="mt-3 mt-md-2">
                                <LabelWrapper
                                  label={t("Types Media")}
                                  isShow={typesSearch?.length > 0}
                                >
                                  <FilterTypes
                                    name="medias"
                                    dataList={listType}
                                    initialValue={typesSearch || []}
                                    onChangeSelect={(event: any) => {
                                      setTypesSearch((_prev) => event);
                                    }}
                                  />
                                </LabelWrapper>
                              </Col>
                            </Row>
                            <Row className="g-4 align-items-center mt-0 mt-md-2">
                              <Col
                                sm={12}
                                md={12}
                                lg={6}
                                className="mt-3 mt-md-2"
                              >
                                <LabelWrapper
                                  label={t("Media Name")}
                                  isShow={!!keywordSearch}
                                >
                                  <Input
                                    type="text"
                                    className="form-control search"
                                    placeholder={`${t("Media Name")}...`}
                                    value={keywordSearch}
                                    onChange={(e) =>
                                      setKeywordSearch(e.target.value)
                                    }
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        searchData();
                                      }
                                    }}
                                  />
                                </LabelWrapper>
                              </Col>
                              <Col
                                sm={12}
                                md={12}
                                lg={6}
                                className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2"
                              >
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-primary me-1"
                                    onClick={searchData}
                                    disabled={isMediaLoading}
                                  >
                                    <i className="ri-search-line align-bottom me-1"></i>{" "}
                                    {t("Button Search")}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-secondary fs-14"
                                    onClick={resetData}
                                  >
                                    <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                    {t("Button Reset")}
                                  </button>
                                </div>
                                {isHavePermissionRole(
                                  ROLES_FOR_APP.MEDIA_CREATE,
                                  userPermissions
                                ) && (
                                    <button
                                      type="button"
                                      className="btn btn-success d-none d-md-block"
                                      onClick={() => onCreateClick()}
                                    >
                                      <i className="ri-add-fill align-bottom me-1"></i>{" "}
                                      {t("Button Create Media")}
                                    </button>
                                  )}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="pt-3">
                      <div>
                        <TableContainer
                          className="custom-header-css"
                          divClass="table-responsive table-card"
                          tableClass="align-middle"
                          theadClass="table-light"
                          columns={columns}
                          data={medias?.list?.length ? medias?.list : []}
                          customPageSize={query.limit}
                          customPageIndex={query.page - 1}
                          totalRecords={medias?.pagination?.total}
                          customPageCount={Math.ceil(
                            Number(medias?.pagination?.total) /
                            Number(medias?.pagination?.limit)
                          )}
                          handleChangePage={handleChangePage}
                          manualSorting={true}
                          sorting={{
                            sort_by: query.sort_by,
                            order_by: query.order_by,
                          }}
                          handleChangeSorting={handleChangeSorting}
                          isLoading={isMediaLoading}
                        />
                      </div>
                      <ToastContainer closeButton={false} limit={1} />
                    </CardBody>
                  </Card>
                </Col>
              )}
          </Row>
        </Container>
        <ModalConfirm
          header={t("Button Delete Media")}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
        <Modal isOpen={isOpen} centered={true} scrollable={true} size="lg">
          <ModalHeader toggle={isLoading ? () => { } : onCloseClick}>
            {!!initialValuesDefault?.id
              ? initialValuesDefault?.isPreview
                ? t("Button Detail Media")
                : t("Button Update Media")
              : t("Button Create Media")}
          </ModalHeader>
          <ModalBody className="py-3 px-5">
            <form onSubmit={formik.handleSubmit}>
              <div className="row g-3">
                <Col xxl={12}>
                  <label className="form-label">
                    {t("Media Name")}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    className="form-control"
                    id="name"
                    name="name"
                    value={formik?.values?.name}
                    placeholder={`${t("Media Name")}...`}
                    onChange={(event: any) =>
                      formik.setFieldValue("name", event?.target?.value || "")
                    }
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger mt-2">{formik.errors.name}</div>
                  ) : null}
                </Col>
                {!!initialValuesDefault?.isPreview && (
                  <>
                    <Col xxl={12}>
                      <label className="form-label">{t("Media ID")}</label>
                      <div className="d-flex">
                        <input
                          className="form-control"
                          id="id"
                          name="id"
                          disabled={true}
                          value={formik?.values?.id}
                          placeholder={`${t("Media ID")}...`}
                          onChange={(event: any) =>
                            formik.setFieldValue(
                              "id",
                              event?.target?.value || ""
                            )
                          }
                        />
                        <button
                          className="btn btn-sm btn-soft-success ms-2 fs-14"
                          onClick={() =>
                            copyTextToClipboard(formik?.values?.id)
                          }
                          type="button"
                        >
                          <i className="ri-file-copy-line align-middle"></i>
                        </button>
                      </div>
                      {formik.touched.id && formik.errors.id ? (
                        <div className="text-danger mt-2">
                          {formik.errors.id}
                        </div>
                      ) : null}
                    </Col>
                    <Col xxl={12}>
                      <label className="form-label">{t("Media API Key")}</label>
                      <div className="d-flex">
                        <input
                          className="form-control w-100"
                          id="api_key"
                          name="api_key"
                          disabled={true}
                          value={formik?.values?.api_key}
                          placeholder={`${t("Media API Key")}...`}
                          onChange={(event: any) =>
                            formik.setFieldValue(
                              "api_key",
                              event?.target?.value || ""
                            )
                          }
                        />
                        <button
                          className="btn btn-sm btn-soft-success ms-2 fs-14"
                          onClick={() =>
                            copyTextToClipboard(formik?.values?.api_key)
                          }
                          type="button"
                        >
                          <i className="ri-file-copy-line align-middle"></i>
                        </button>
                      </div>
                      {formik.touched.api_key && formik.errors.api_key ? (
                        <div className="text-danger mt-2">
                          {formik.errors.api_key}
                        </div>
                      ) : null}
                    </Col>
                    <Col xxl={12}>
                      <label className="form-label">
                        {t("Media API Secret")}
                      </label>
                      <div className="d-flex">
                        <div className="position-relative auth-pass-inputgroup w-100 ">
                          <input
                            className="form-control"
                            id="api_secret"
                            name="api_secret"
                            disabled={true}
                            type={passwordShow ? "text" : "password"}
                            value={formik?.values?.api_secret}
                            placeholder={`${t("Media API Secret")}...`}
                            onChange={(event: any) =>
                              formik.setFieldValue(
                                "api_secret",
                                event?.target?.value || ""
                              )
                            }
                          />
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            onClick={() => setPasswordShow(!passwordShow)}
                            type="button"
                            id="password-addon"
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                        {isHavePermissionRole(
                          ROLES_FOR_APP.MEDIA_REFRESH_KEY,
                          userPermissions
                        ) && (
                            <button
                              className="btn btn-sm btn-soft-primary ms-2 fs-14"
                              onClick={() =>
                                setIsConfirmRefreshKey((prev) => true)
                              }
                              type="button"
                            >
                              <i className="ri-refresh-line align-middle"></i>
                            </button>
                          )}
                        <button
                          className="btn btn-sm btn-soft-success ms-2 fs-14"
                          onClick={() =>
                            copyTextToClipboard(formik?.values?.api_secret)
                          }
                          type="button"
                        >
                          <i className="ri-file-copy-line align-middle"></i>
                        </button>
                      </div>

                      {formik.touched.api_secret && formik.errors.api_secret ? (
                        <div className="text-danger mt-2">
                          {formik.errors.api_secret}
                        </div>
                      ) : null}
                    </Col>
                  </>
                )}
                <Col xxl={12}>
                  <label className="form-label">{t("Description")}</label>
                  <input
                    className="form-control"
                    id="description"
                    name="description"
                    value={formik?.values?.description}
                    placeholder={`${t("Description")}...`}
                    onChange={(event: any) =>
                      formik.setFieldValue(
                        "description",
                        event?.target?.value || ""
                      )
                    }
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <div className="text-danger mt-2">
                      {formik.errors.description}
                    </div>
                  ) : null}
                </Col>
                <Col xxl={12}>
                  <label className="form-label">{t("Website")}</label>
                  <input
                    className="form-control"
                    id="website"
                    name="website"
                    value={formik?.values?.website}
                    placeholder={`${t("Website")}...`}
                    onChange={(event: any) =>
                      formik.setFieldValue(
                        "website",
                        event?.target?.value || ""
                      )
                    }
                  />
                  {formik.touched.website && formik.errors.website ? (
                    <div className="text-danger mt-2">
                      {formik.errors.website}
                    </div>
                  ) : null}
                </Col>
                <Col xxl={12}>
                  <label className="form-label">{t("News Url Format")} <TooltipCustom
                    title={t("Example: example.co.kr/news/articleView.html?idxno={id}, with {id} is required in url")}
                    className="d-inline-block vertical-align-middle ms-1"
                    style={{ transform: 'translateY(1px)' }}
                  >
                    <i className="ri-question-line align-bottom text-secondary" ></i>
                  </TooltipCustom></label>
                  <input
                    className="form-control"
                    id="news_url_format"
                    name="news_url_format"
                    value={formik?.values?.news_url_format}
                    placeholder={`${t("example.co.kr/news/articleView.html?idxno={id}")}...`}
                    onChange={(event: any) =>
                      formik.setFieldValue(
                        "news_url_format",
                        event?.target?.value || ""
                      )
                    }
                  />
                  {formik.touched.news_url_format && formik.errors.news_url_format ? (
                    <div className="text-danger mt-2">
                      {formik.errors.news_url_format}
                    </div>
                  ) : null}
                </Col>

                <Col xxl={12}>
                  <label className="form-label">
                    {t("Media transmission format classification")}
                    <span className="text-danger"> *</span>
                  </label>
                  <Select
                    name="format"
                    options={TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS}
                    value={formik?.values?.format}
                    onChange={(event: any) =>
                      formik.setFieldValue("format", event)
                    }
                    placeholder={
                      <div>{`${t(
                        "Media transmission format classification"
                      )}...`}</div>
                    }
                  />
                </Col>
                <Col xxl={12}>
                  <div>
                    <label className="form-label">
                      {t("Types Media")}
                      <span className="text-danger"> *</span>
                    </label>
                    <Types
                      name="types"
                      placeholder={`${t("Types Media")}...`}
                      suggestions={suggestionsTypes || []}
                      initialValue={initialValueTypes || []}
                      onChangeTag={(event) => {
                        formik.setFieldValue("types", event);
                      }}
                    />
                    {formik.touched.types && formik.errors.types ? (
                      <div className="text-danger mt-2">
                        {formik.errors.types}
                      </div>
                    ) : null}
                  </div>
                </Col>
                {/* Begin::List Section */}
                {/* formik?.values?.sections ||  */}
                <div>
                  {initialValueSections?.map((item: any, index: number) => (
                    <div
                      className="p-4 position-relative mt-4"
                      key={item?.id || index}
                      style={{
                        border: "1px dashed var(--vz-primary)",
                        backgroundColor: "var(--vz-light)",
                        borderRadius: "5px",
                      }}
                    >
                      {/* Begin::Item Template For Media */}
                      <div
                        className="position-absolute top-0"
                        style={{
                          transform: "translateY(-50%)",
                          padding: "5px 10px",
                          backgroundColor: "var(--vz-primary-bg-subtle)",
                          color: "var(--vz-primary)",
                          borderRadius: "5px",
                          border: "1px dashed var(--vz-primary)",
                          fontWeight: 500,
                        }}
                      >
                        {t("Section")} {index + 1}
                      </div>
                      {!initialValuesDefault?.isPreview && (
                        <div
                          className="position-absolute end-0 top-0"
                          style={{
                            transform: "translateY(-50%)",
                            padding: "5px 10px",
                            // backgroundColor: 'var(--vz-primary-bg-subtle)',
                            // color: 'var(--vz-primary)',
                            // borderRadius: '5px',
                            // border: '1px dashed var(--vz-primary)',
                            fontWeight: 500,
                          }}
                        >
                          {index > 0 && (
                            <button
                              className="btn btn-sm btn-danger edit-item-btn"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                handleRemoveSection(item);
                              }}
                            >
                              <i className="ri-close-circle-line align-bottom "></i>
                            </button>
                          )}
                          {initialValueSections?.length === index + 1 && (
                            <button
                              className="btn btn-sm btn-success edit-item-btn ms-2"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                handleAddSection();
                              }}
                            >
                              <i className="ri-add-circle-line align-bottom "></i>
                            </button>
                          )}
                        </div>
                      )}
                      <Row>
                        <Col md={3}>
                          <label className="form-label">
                            {t("Section Title")}
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            className="form-control"
                            id={`sections[${index}]section_title`}
                            name={`sections[${index}]section_title`}
                            value={
                              (formik?.values?.sections?.[index] as any)
                                ?.section_title
                            }
                            placeholder={`${t("Section Title")}...`}
                            onChange={(event: any) =>
                              formik.setFieldValue(
                                `sections[${index}]section_title`,
                                event?.target?.value || ""
                              )
                            }
                          />
                          {(formik.touched?.sections?.[index] as any)
                            ?.section_title &&
                            (formik.errors?.sections?.[index] as any)
                              ?.section_title ? (
                            <div className="text-danger mt-2">
                              {
                                (formik.errors?.sections?.[index] as any)
                                  ?.section_title
                              }
                            </div>
                          ) : null}
                        </Col>
                        <Col md={3}>
                          <label className="form-label">
                            {t("Section Code")}
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            className="form-control"
                            id={`sections[${index}]section_code`}
                            name={`sections[${index}]section_code`}
                            value={
                              (formik?.values?.sections?.[index] as any)
                                ?.section_code
                            }
                            placeholder={`${t("Section Code")}...`}
                            onChange={(event: any) =>
                              formik.setFieldValue(
                                `sections[${index}]section_code`,
                                event?.target?.value || ""
                              )
                            }
                          />
                          {(formik.touched?.sections?.[index] as any)
                            ?.section_code &&
                            (formik.errors?.sections?.[index] as any)
                              ?.section_code ? (
                            <div className="text-danger mt-2">
                              {
                                (formik.errors?.sections?.[index] as any)
                                  ?.section_code
                              }
                            </div>
                          ) : null}
                        </Col>
                        <Col md={3}>
                          <label className="form-label">
                            {t("Reporter")}
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            className="form-control"
                            id={`sections[${index}]reporter`}
                            name={`sections[${index}]reporter`}
                            value={
                              (formik?.values?.sections?.[index] as any)
                                ?.reporter
                            }
                            placeholder={`${t("Reporter")}...`}
                            onChange={(event: any) =>
                              formik.setFieldValue(
                                `sections[${index}]reporter`,
                                event?.target?.value || ""
                              )
                            }
                          />
                          {(formik.touched?.sections?.[index] as any)
                            ?.reporter &&
                            (formik.errors?.sections?.[index] as any)
                              ?.reporter ? (
                            <div className="text-danger mt-2">
                              {
                                (formik.errors?.sections?.[index] as any)
                                  ?.reporter
                              }
                            </div>
                          ) : null}
                        </Col>
                        <Col md={3}>
                          <label className="form-label">
                            {t("Reporter Id")}
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            className="form-control"
                            id={`sections[${index}]reporter_id`}
                            name={`sections[${index}]reporter_id`}
                            value={
                              (formik?.values?.sections?.[index] as any)
                                ?.reporter_id
                            }
                            placeholder={`${t("Reporter Id")}...`}
                            onChange={(event: any) =>
                              formik.setFieldValue(
                                `sections[${index}]reporter_id`,
                                event?.target?.value || ""
                              )
                            }
                          />
                          {(formik.touched?.sections?.[index] as any)
                            ?.reporter_id &&
                            (formik.errors?.sections?.[index] as any)
                              ?.reporter_id ? (
                            <div className="text-danger mt-2">
                              {
                                (formik.errors?.sections?.[index] as any)
                                  ?.reporter_id
                              }
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
                {/* End::List Section */}
                <div className="col-lg-12">
                  <div className="hstack gap-2 justify-content-end">
                    {!initialValuesDefault?.isPreview && (
                      <button
                        className="btn btn-primary fs-14"
                        color="success"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner size="sm" className="me-2"></Spinner>
                        ) : !!initialValuesDefault?.id ? (
                          <i className="ri-login-circle-line align-bottom me-1"></i>
                        ) : (
                          <i className="ri-add-fill align-bottom me-1"></i>
                        )}
                        {!!initialValuesDefault?.id
                          ? t("Button Update Media")
                          : t("Button Create Media")}
                      </button>
                    )}
                    <button
                      className="btn btn-soft-secondary fs-14"
                      color="light"
                      type="button"
                      onClick={onCloseClick}
                      disabled={isLoading}
                    >
                      <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                      {t("Button Close")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={isOpenUpdateAccountMedia}
          centered={true}
          scrollable={true}
          size="lg"
        >
          <ModalHeader
            toggle={isLoading ? () => { } : onCloseUpdateAccountClick}
          >
            {t("Button Update Account Media")}
          </ModalHeader>
          <ModalBody className="py-3 px-5">
            <form onSubmit={formikAccount.handleSubmit}>
              <div className="row g-3">
                <div
                  className="p-4 position-relative mt-4"
                  style={{
                    border: "1px dashed var(--vz-primary)",
                    backgroundColor: "var(--vz-light)",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    className="position-absolute top-0"
                    style={{
                      transform: "translateY(-50%)",
                      padding: "5px 10px",
                      backgroundColor: "var(--vz-primary-bg-subtle)",
                      color: "var(--vz-primary)",
                      borderRadius: "5px",
                      border: "1px dashed var(--vz-primary)",
                      fontWeight: 500,
                    }}
                  >
                    {t("Account Info")}
                  </div>
                  <Col xxl={12} className="mt-2">
                    <label className="form-label">
                      {t("Email")}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      className="form-control"
                      id="email"
                      name="email"
                      value={formikAccount?.values?.email}
                      placeholder={`${t("Enter email")}...`}
                      onChange={(event: any) =>
                        formikAccount.setFieldValue(
                          "email",
                          event?.target?.value || ""
                        )
                      }
                    />
                    {formikAccount.touched.email &&
                      formikAccount.errors.email ? (
                      <div className="text-danger mt-2">
                        {formikAccount.errors.email}
                      </div>
                    ) : null}
                  </Col>
                  <Col xxl="12 mt-3">
                    <label className="form-label" htmlFor="password-input">
                      {t("Password")}
                    </label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <input
                        name="password"
                        value={formikAccount?.values?.password || ""}
                        type={passwordShow ? "text" : "password"}
                        className="form-control pe-5"
                        placeholder={t("Enter Password")}
                        onChange={(event: any) =>
                          formikAccount.setFieldValue(
                            "password",
                            event?.target?.value || ""
                          )
                        }
                      />
                      {formikAccount.touched.password &&
                        formikAccount.errors.password ? (
                        <div className="text-danger mt-2">
                          {formikAccount.errors.password}
                        </div>
                      ) : null}
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                        onClick={() => setPasswordShow(!passwordShow)}
                        type="button"
                        id="password-addon"
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </button>
                    </div>
                  </Col>
                </div>
                <div
                  className="p-4 position-relative mt-4"
                  style={{
                    border: "1px dashed var(--vz-primary)",
                    backgroundColor: "var(--vz-light)",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    className="position-absolute top-0"
                    style={{
                      transform: "translateY(-50%)",
                      padding: "5px 10px",
                      backgroundColor: "var(--vz-primary-bg-subtle)",
                      color: "var(--vz-primary)",
                      borderRadius: "5px",
                      border: "1px dashed var(--vz-primary)",
                      fontWeight: 500,
                    }}
                  >
                    {t("Contact Info")}
                  </div>
                  <Col xxl={12} className="mt-2">
                    <label className="form-label">{t("Profile Url")}</label>
                    <input
                      className="form-control"
                      id="profile_url"
                      name="profile_url"
                      value={formikAccount?.values?.profile_url}
                      placeholder={`${t("Profile Url")}...`}
                      onChange={(event: any) =>
                        formikAccount.setFieldValue(
                          "profile_url",
                          event?.target?.value || ""
                        )
                      }
                    />
                    {formikAccount.touched.profile_url &&
                      formikAccount.errors.profile_url ? (
                      <div className="text-danger mt-2">
                        {formikAccount.errors.profile_url}
                      </div>
                    ) : null}
                  </Col>
                  <Col xxl={12} className="mt-3">
                    <label className="form-label">{t("Contact Name")}</label>
                    <input
                      className="form-control"
                      id="contact_name"
                      name="contact_name"
                      value={formikAccount?.values?.contact_name}
                      placeholder={`${t("Contact Name")}...`}
                      onChange={(event: any) =>
                        formikAccount.setFieldValue(
                          "contact_name",
                          event?.target?.value || ""
                        )
                      }
                    />
                    {formikAccount.touched.contact_name &&
                      formikAccount.errors.contact_name ? (
                      <div className="text-danger mt-2">
                        {formikAccount.errors.contact_name}
                      </div>
                    ) : null}
                  </Col>
                  <Col xxl={12} className="mt-3">
                    <label className="form-label">{t("Contact Phone")}</label>
                    <input
                      className="form-control"
                      id="contact_phone"
                      name="contact_phone"
                      value={formikAccount?.values?.contact_phone}
                      placeholder={`${t("Contact Phone")}...`}
                      onChange={(event: any) =>
                        formikAccount.setFieldValue(
                          "contact_phone",
                          event?.target?.value || ""
                        )
                      }
                    />
                    {formikAccount.touched.contact_phone &&
                      formikAccount.errors.contact_phone ? (
                      <div className="text-danger mt-2">
                        {formikAccount.errors.contact_phone}
                      </div>
                    ) : null}
                  </Col>
                  <Col xxl={12} className="mt-3">
                    <label className="form-label">{t("Contact Email")}</label>
                    <input
                      className="form-control"
                      id="contact_email"
                      name="contact_email"
                      value={formikAccount?.values?.contact_email}
                      placeholder={`${t("Contact Email")}...`}
                      onChange={(event: any) =>
                        formikAccount.setFieldValue(
                          "contact_email",
                          event?.target?.value || ""
                        )
                      }
                    />
                    {formikAccount.touched.contact_email &&
                      formikAccount.errors.contact_email ? (
                      <div className="text-danger mt-2">
                        {formikAccount.errors.contact_email}
                      </div>
                    ) : null}
                  </Col>
                </div>
                {/* Begin::List Section */}
                {/* End::List Section */}
                <div className="col-lg-12">
                  <div className="hstack gap-2 justify-content-end">
                    <button
                      className="btn btn-primary fs-14"
                      color="success"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Spinner size="sm" className="me-2"></Spinner>
                      ) : (
                        <i className="ri-login-circle-line align-bottom me-1"></i>
                      )}
                      {t("Button Update Account Media")}
                    </button>
                    <button
                      className="btn btn-soft-secondary fs-14"
                      color="light"
                      type="button"
                      onClick={onCloseUpdateAccountClick}
                      disabled={isLoading}
                    >
                      <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                      {t("Button Close")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={isOpenUpdateConfigMedia}
          centered={true}
          scrollable={true}
          size="lg"
          onClosed={onCloseConfigClick}
        >
          <ModalHeader toggle={isLoadingConfig ? () => { } : onCloseConfigClick}>
            {t("Config Media")}
          </ModalHeader>
          <ModalBody className="py-3 px-5">
            <div style={{ minHeight: 400 }} className="row g-3">
              {isLoadingConfig && (
                <Spinner size="xl" className="text-primary m-auto"></Spinner>
              )}
              {!isLoadingConfig &&
                configDetail &&
                configDetail?.length > 0 &&
                configDetail?.map((item: any, index: any) => (
                  <div
                    className="p-4 position-relative mt-4"
                    style={{
                      border: "1px dashed var(--vz-primary)",
                      backgroundColor: "var(--vz-light)",
                      borderRadius: "5px",
                    }}
                    key={index}
                  >
                    <div
                      className="position-absolute top-0"
                      style={{
                        transform: "translateY(-50%)",
                        padding: "5px 10px",
                        backgroundColor: "var(--vz-primary-bg-subtle)",
                        color: "var(--vz-primary)",
                        borderRadius: "5px",
                        border: "1px dashed var(--vz-primary)",
                        fontWeight: 500,
                      }}
                    >
                      {t(item?.group)}
                    </div>
                    <Col xxl={12} className="mt-2 gap-2 d-flex flex-column">
                      {item?.items?.map((itemField: any, index: any) => (
                        <div key={index}>
                          <label className="form-label">
                            {t(itemField?.key)}
                            <span className="text-danger"> *</span>
                          </label>
                          <div
                            className={`hidden d-flex gap-2 align-items-end`}
                          >
                            {itemField?.key === "news_byline" ? (
                              <EditorCustom
                                initialValue={itemField?.value || ""}
                                toolbar=""
                                ref={refContentNews}
                                height="120px"
                                width="100%"
                              />
                            ) : (
                              <input
                                className="form-control"
                                defaultValue={itemField?.value}
                                placeholder={`${t("Enter value")}...`}
                                onChange={(event: any) =>
                                  setTargetField({
                                    key: itemField?.key,
                                    value: event?.target?.value,
                                  })
                                }
                              />
                            )}

                            <Button
                              style={{ height: 39, minWidth: 80 }}
                              className={`rounded ${itemField?.key === "news_byline" && "mt-2"
                                }`}
                              color="primary"
                              disabled={
                                isLoadingUpdateConfig === itemField?.key
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                handleUpdateConfig(
                                  initialValuesDefault?.id,
                                  itemField?.key,
                                  {
                                    key: itemField?.key,
                                    value:
                                      itemField?.key === "news_byline"
                                        ? refContentNews?.current?.getContent()
                                        : targetField?.value,
                                  }
                                )
                              }
                            >
                              {isLoadingUpdateConfig &&
                                isLoadingUpdateConfig === itemField?.key ? (
                                <Spinner size="sm" className="me-2"></Spinner>
                              ) : (
                                t("Update")
                              )}
                            </Button>
                          </div>
                        </div>
                      ))}
                    </Col>
                  </div>
                ))}
              {!isLoadingConfig && (
                <div className="col-lg-12">
                  <div className="hstack gap-2 justify-content-end">
                    <button
                      className="btn btn-soft-secondary fs-14"
                      color="light"
                      type="button"
                      onClick={onCloseConfigClick}
                      disabled={isLoadingConfig}
                    >
                      <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                      {t("Button Close")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={!!initialValuesDefault?.is_update_ads_account}
          centered={true}
          scrollable={true}
          size="xl"
        >
          <ModalHeader
            toggle={isLoading ? () => { } : onCloseUpdateAdsAccountClick}
          >
            {t("Ads Account")}
          </ModalHeader>
          <ModalBody className="py-3 pt-0 px-4">
            <div className="table-responsive" style={{ minHeight: "450px" }}>
              <Table className="caption-top table-bordered table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col" colSpan={5} className="text-center bold">
                      {initialValuesDefault?.name || ""}
                    </th>
                  </tr>
                  <tr>
                    <th scope="col" className="text-center bold">
                      {t("Agency")}
                    </th>
                    <th scope="col" className="text-center bold">
                      {t("System Revenue Rate")}
                    </th>
                    <th scope="col" className="text-center bold">
                      {t("Partner Revenue Rate")}
                    </th>
                    <th scope="col" className="text-center bold">
                      {t("Effective Date")}
                    </th>
                    <th scope="col" className="text-center bold">
                      {t("Button Action")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {adsAccount?.length === 0 && (
                    <tr>
                      <td colSpan={5} className="text-center">
                        {t("No Data")}
                      </td>
                    </tr>
                  )}
                  {adsAccount?.map((item: IAdsAccount) => (
                    <tr key={item?.id}>
                      <td className="align-middle text-center h6">
                        <div className="w-100 text-center">
                          <img
                            height="20"
                            alt=""
                            src={item?.agency?.logo_url || ""}
                          />
                        </div>
                      </td>
                      <td className={`align-middle text-center h6`}>
                        <input
                          className="form-control text-center"
                          id={`cell-${item?.id}-system_revenue_rate`}
                          name={`cell-${item?.id}-system_revenue_rate`}
                          value={
                            valueRate[`cell-${item?.id}`]?.system_revenue_rate
                          }
                          placeholder={`${t("System Revenue Rate")}...`}
                          disabled
                          onChange={(event: any) => {
                            const input = event.target.value;
                            const onlyNumbers = input.replace(/\D/g, "");
                            const s =
                              Number(onlyNumbers) >= 100
                                ? 100
                                : Number(onlyNumbers);
                            setValueRate({
                              ...valueRate,
                              [`cell-${item?.id}`]: {
                                ...valueRate[`cell-${item?.id}`],
                                partner_revenue_rate: 100 - s,
                                system_revenue_rate: s,
                              },
                            });
                          }}
                        />
                      </td>
                      <td className={`align-middle text-center h6`}>
                        <input
                          className="form-control text-center"
                          id={`cell-${item?.id}-partner_revenue_rate`}
                          name={`cell-${item?.id}-partner_revenue_rate`}
                          value={
                            valueRate[`cell-${item?.id}`]?.partner_revenue_rate
                          }
                          placeholder={`${t("Partner Revenue Rate")}...`}
                          disabled
                          onChange={(event: any) => {
                            const input = event.target.value;
                            const onlyNumbers = input.replace(/\D/g, "");
                            const p =
                              Number(onlyNumbers) >= 100
                                ? 100
                                : Number(onlyNumbers);
                            setValueRate({
                              ...valueRate,
                              [`cell-${item?.id}`]: {
                                ...valueRate[`cell-${item?.id}`],
                                system_revenue_rate: 100 - p,
                                partner_revenue_rate: p,
                              },
                            });
                          }}
                        />
                      </td>
                      <td className="align-middle">
                        <div
                          className="date-picker-wrapper-custom"
                          style={{ width: "100%" }}
                        >
                          <DatePickerCustom
                            placeholder={`${t("Effective from")}  >  ${t(
                              "Effective to"
                            )}`}
                            startDate={
                              valueRate[`cell-${item?.id}`]?.effective_from ||
                              null
                            }
                            endDate={
                              valueRate[`cell-${item?.id}`]?.effective_to ||
                              null
                            }
                            // minDate={new Date()}
                            disabled
                            onChangePicker={(event: any) => {
                              setValueRate({
                                ...valueRate,
                                [`cell-${item?.id}`]: {
                                  ...valueRate[`cell-${item?.id}`],
                                  effective_from: event[0],
                                  effective_to: event[1],
                                },
                              });
                            }}
                          />
                        </div>
                      </td>
                      <td
                        className={`align-middle text-center h6 text-danger`}
                        style={{ width: "100px" }}
                      >
                        <Link
                          className={`m-auto btn btn-sm btn-success edit-item-btn d-flex align-items-center justify-content-center ${idAdsAccountLoading === item?.id ? "disabled" : ""
                            }`}
                          to="#"
                          style={{ height: "38px", width: "85px" }}
                          onClick={(e) => {
                            e.preventDefault();
                            setAccountShowRevenueRate((prev: any) => ({
                              id: item?.id,
                              name: item?.agency?.name,
                            }));
                          }}
                        >
                          {idAdsAccountLoading === item?.id ? (
                            <Spinner size="sm"></Spinner>
                          ) : (
                            <span>{t("Button More")}</span>
                          )}
                        </Link>
                        <Link
                          className={`m-auto btn mt-2 btn-sm btn-secondary edit-item-btn d-flex align-items-center justify-content-center ${idAdsAccountLoading === item?.id ? "disabled" : ""
                            }`}
                          to="#"
                          style={{ height: "38px", width: "85px" }}
                          onClick={(e) => {
                            e.preventDefault();
                            setAccountIdShowHistory((prev: any) => item?.id);
                          }}
                        >
                          {t("Button History Ads Account")}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="col-lg-12 mt-4">
              <div className="hstack gap-2 justify-content-end">
                <button
                  className="btn btn-soft-secondary fs-14"
                  color="light"
                  type="button"
                  onClick={onCloseUpdateAdsAccountClick}
                  disabled={isLoading}
                >
                  <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                  {t("Button Close")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={!!accountShowRevenueRate}
          centered={true}
          scrollable={true}
          size="xl"
        >
          <ModalHeader
            toggle={() =>
              setAccountShowRevenueRate((prev: any) => (prev ? null : prev))
            }
          >
            {t("Revenue Rate")}
          </ModalHeader>
          <ModalBody className="py-3 pt-0 px-4">
            <div style={{ minHeight: "450px" }}>
              <RevenueRate
                accountId={accountShowRevenueRate?.id}
                nameAdsAccount={accountShowRevenueRate?.name}
                handleActionRefresh={() =>
                  handleShowAdsAccountEdit(initialValuesDefault as IMedia)
                }
                handleCloseModal={() =>
                  setAccountShowRevenueRate((prev: any) => null)
                }
              />
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={!!accountIdShowHistory}
          centered={true}
          scrollable={true}
          size="xl"
        >
          <ModalHeader
            toggle={() =>
              setAccountIdShowHistory((prev: any) => (prev ? null : prev))
            }
          >
            {t("History Ads Account")}
          </ModalHeader>
          <ModalBody className="py-3 pt-0 px-4">
            <div className="table-responsive" style={{ minHeight: "450px" }}>
              <History accountId={accountIdShowHistory} />
            </div>
            <div className="col-lg-12 mt-4">
              <div className="hstack gap-2 justify-content-end">
                <button
                  className="btn btn-soft-secondary fs-14"
                  color="light"
                  type="button"
                  onClick={() => setAccountIdShowHistory(null)}
                >
                  <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                  {t("Button Close")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <ModalConfirm
          textButtonConfirm={t("Button Refresh Key Media")}
          classButtonConfirm="btn-soft-primary"
          classIconButtonConfirm="ri-refresh-line"
          header={t("Button Refresh Key Media")}
          title={t("Do you want to refresh key of media!")}
          content={t(
            "After confirming the refresh action, the data will be updated and cannot be recovered. Do you want to proceed with the refresh action."
          )}
          isShowIcon={false}
          isOpen={isOpenConfirmRefreshKey}
          isLoading={isConfirmRefreshKeyLoading}
          onClose={onCloseConfirmRefreshKeyClick}
          onConfirm={handleActionRefreshKey}
        />
      </div>
    </React.Fragment>
  );
};

export default MediaList;
